
import { getCdnImagePath } from '@/utils/cdn';

import { mapGettersOfMyPage, GET_TOTAL_CREDIT, GET_EXTINCTION_CREDIT } from '@/store/myPage';

import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';

import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'MyPageCreditInfoBoard',
	mixins: [layoutMixin, routeMixin],
	props: {
		showDescription: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGettersOfMyPage([GET_TOTAL_CREDIT, GET_EXTINCTION_CREDIT]),
		myPageCreditListInfoBoard() {
			return this.IS_MOBILE ? [24, 16, 24, 16] : [0, 0, 32, 0];
		},
		myPageCreditListInfoBoardBoxPadding() {
			return this.IS_MOBILE ? [20, 16, 20, 16] : [20, 20, 20, 20];
		},
	},
	methods: {
		getCdnImagePath,
	},
	components: { Skeleton },
};
