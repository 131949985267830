import { createNamespacedHelpers } from 'vuex';

import { CLASS_PT_SALE_TYPES } from '@/constants/class/pt/class-types';
import { VOD_APPLY_LECTURE_PATH } from '@/constants/paths';
const NAMESPACE = 'vod/lecture';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfVodLecture,
	mapMutations as mapMutationsOfVodLecture,
	mapActions as mapActionsOfVodLecture,
};

// getters
export const VOD_LECTURE_ID = 'VOD_LECTURE_ID';
export const VOD_LECTURE_INFO = 'VOD_LECTURE_INFO';
export const VOD_LECTURE_DISCOUNT_INFO = 'VOD_LECTURE_DISCOUNT_INFO';
export const VOD_LECTURE_CONTENT = 'VOD_LECTURE_CONTENT';
export const VOD_LECTURE_IS_DUMMY = 'VOD_LECTURE_IS_DUMMY';
export const VOD_LECTURE_IS_CLOSED = 'VOD_LECTURE_IS_CLOSED';
export const VOD_LECTURE_CATEGORY = 'VOD_LECTURE_CATEGORY';
export const VOD_LECTURE_LECTURE = 'VOD_LECTURE_LECTURE';
export const VOD_LECTURE_PRICING = 'VOD_LECTURE_PRICING';
export const VOD_LECTURE_SALE_INFO = 'VOD_LECTURE_SALE_INFO';
export const VOD_LECTURE_CHIPS = 'VOD_LECTURE_CHIPS';
export const VOD_LECTURE_DOMINANT_COLOR = 'VOD_LECTURE_DOMINANT_COLOR';
export const VOD_LECTURE_PAIN_POINT = 'VOD_LECTURE_PAIN_POINT';
export const VOD_LECTURE_AFTER_FIGURE = 'VOD_LECTURE_AFTER_FIGURE';
export const VOD_LECTURE_USER_TARGET = 'VOD_LECTURE_USER_TARGET';
export const VOD_LECTURE_CONFIGURATION = 'VOD_LECTURE_CONFIGURATION';
export const VOD_LECTURE_CURRICULUMS = 'VOD_LECTURE_CURRICULUMS';
export const VOD_LECTURE_FAQ = 'VOD_LECTURE_FAQ';
export const VOD_LECTURE_MENTOR = 'VOD_LECTURE_MENTOR';
export const VOD_LECTURE_ORDER_LIST = 'VOD_LECTURE_ORDER_LIST';
export const VOD_LECTURE_URL = 'VOD_LECTURE_URL';
export const VOD_LECTURE_VOD_OFFER = 'VOD_LECTURE_VOD_OFFER';
export const VOD_LECTURE_RELATION_LIST = 'VOD_LECTURE_RELATION_LIST';
export const VOD_LECTURE_IS_PAID = 'VOD_LECTURE_IS_PAID';
export const KDC_NOW_PROGRESSING = 'KDC_NOW_PROGRESSING';
export const VOD_LECTURE_CTA_BUTTON_NAME = 'VOD_LECTURE_CTA_BUTTON_NAME';
export const KDC_CHECK_CARD_MODAL = 'KDC_CHECK_CARD_MODAL';
export const KDC_SELF_AUTH_CHECK = 'KDC_SELF_AUTH_CHECK';
export const OPEN_KDC_REDIRECT_MODAL = 'OPEN_KDC_REDIRECT_MODAL';
export const IS_KDC_USER = 'IS_KDC_USER';
export const IS_KDC_CLASS = 'IS_KDC_CLASS';
export const IS_LOGIN_MODAL_VISIBLE = 'IS_LOGIN_MODAL_VISIBLE';
export const VOD_DETAIL_TAB_INDEX = 'VOD_DETAIL_TAB_INDEX';
export const IS_KDC_TAB_SELECTED = 'IS_KDC_TAB_SELECTED';

export const VOD_LECTURE_PURCHASE_TIME = 'VOD_LECTURE_PURCHASE_TIME';

// mutations
export const SET_VOD_LECTURE_ID = 'SET_VOD_LECTURE_ID';
export const SET_VOD_LECTURE_INFO = 'SET_VOD_LECTURE_INFO';
export const SET_VOD_LECTURE_CONTENT = 'SET_VOD_LECTURE_CONTENT';
export const SET_VOD_LECTURE_SALE_INFO = 'SET_VOD_LECTURE_SALE_INFO';
export const SET_VOD_LECTURE_IS_CLOSED = 'SET_VOD_LECTURE_IS_CLOSED';
export const SET_VOD_LECTURE_URL = 'SET_VOD_LECTURE_URL';
export const SET_VOD_LECTURE_RELATION_LIST = 'SET_VOD_LECTURE_RELATION_LIST';
export const SET_VOD_LECTURE_IS_PAID = 'SET_VOD_LECTURE_IS_PAID';
export const SET_KDC_NOW_PROGRESSING = 'SET_KDC_NOW_PROGRESSING';
export const SET_KDC_CHECK_CARD_MODAL = 'SET_KDC_CHECK_CARD_MODAL';
export const SET_KDC_SELF_AUTH_CHECK = 'SET_KDC_SELF_AUTH_CHECK';
export const SET_OPEN_KDC_REDIRECT_MODAL = 'SET_OPEN_KDC_REDIRECT_MODAL';
export const SET_IS_KDC_USER = 'SET_IS_KDC_USER';
export const SET_VOD_DETAIL_TAB_INDEX = 'SET_VOD_DETAIL_TAB_INDEX';

export const SET_LOGIN_MODAL_VISIBLE = 'SET_LOGIN_MODAL_VISIBLE';

export const SET_VOD_LECTURE_PURCHASE_TIME = 'SET_VOD_LECTURE_PURCHASE_TIME';

// actions
export const APPLY_LECTURE = 'APPLY_LECTURE';

export const state = () => ({
	[VOD_LECTURE_INFO]: {},
	[VOD_LECTURE_CONTENT]: {},
	[VOD_LECTURE_RELATION_LIST]: [],
	[IS_LOGIN_MODAL_VISIBLE]: false,
	[VOD_LECTURE_PURCHASE_TIME]: null,
	[KDC_CHECK_CARD_MODAL]: false,
	[KDC_SELF_AUTH_CHECK]: false,
	[OPEN_KDC_REDIRECT_MODAL]: false,
	[IS_KDC_USER]: false,
	[VOD_DETAIL_TAB_INDEX]: 0,
	[VOD_LECTURE_IS_PAID]: false,
});

export const getters = {
	[VOD_LECTURE_ID]: state => state[VOD_LECTURE_INFO].id,
	[VOD_LECTURE_INFO]: state => state[VOD_LECTURE_INFO],
	[VOD_LECTURE_DISCOUNT_INFO]: (_, getters) => {
		const { price, discountRate } = getters[VOD_LECTURE_PRICING];
		return {
			text: CLASS_PT_SALE_TYPES.superEarlyBird,
			price: price * (discountRate / 100),
			rate: discountRate,
		};
	},
	[VOD_LECTURE_CONTENT]: state => state[VOD_LECTURE_CONTENT],
	[VOD_LECTURE_IS_DUMMY]: state => state[VOD_LECTURE_INFO].isDummy,
	[VOD_LECTURE_IS_CLOSED]: state =>
		state[VOD_LECTURE_INFO].progress === '모집마감' || state[VOD_LECTURE_INFO].progress === '종료(비공개)',
	[VOD_LECTURE_CATEGORY]: state => {
		return {
			industry: state[VOD_LECTURE_INFO].industry,
			midCategory: state[VOD_LECTURE_INFO].midCategory,
			topCategory: state[VOD_LECTURE_INFO].topCategory,
			bottomCategory: state[VOD_LECTURE_INFO].bottomCategory,
		};
	},
	[VOD_LECTURE_LECTURE]: state => {
		return {
			lectureTitle: state[VOD_LECTURE_INFO].lectureTitle,
			period: state[VOD_LECTURE_INFO].period,
			personLimit: state[VOD_LECTURE_INFO].personLimit,
			startDate: state[VOD_LECTURE_INFO].startDate,
			recruitEndDate: state[VOD_LECTURE_INFO].recruitEndDate,
			releaseDate: state[VOD_LECTURE_INFO].releaseDate,
			totalTime: state[VOD_LECTURE_INFO].totalTime,
			titleImage: state[VOD_LECTURE_INFO].titleImage,
			dominantColor: state[VOD_LECTURE_INFO].dominantColor,
			subTitle: state[VOD_LECTURE_INFO].lectureSubTitle,
		};
	},
	[VOD_LECTURE_CTA_BUTTON_NAME]: state => state[VOD_LECTURE_INFO].ctaButtonName,
	[VOD_LECTURE_PRICING]: state => state[VOD_LECTURE_INFO].pricing,
	[VOD_LECTURE_CHIPS]: state => state[VOD_LECTURE_INFO].classChips,
	[VOD_LECTURE_DOMINANT_COLOR]: state => state[VOD_LECTURE_INFO].dominantColor,
	[VOD_LECTURE_MENTOR]: state => {
		return {
			mentorData: state[VOD_LECTURE_INFO].mentorData,
			mentor: state[VOD_LECTURE_INFO].mentor,
		};
	},
	[VOD_LECTURE_ORDER_LIST]: state => state[VOD_LECTURE_INFO].orderList,
	[VOD_LECTURE_PAIN_POINT]: state => state[VOD_LECTURE_CONTENT].painPoint,
	[VOD_LECTURE_AFTER_FIGURE]: state => state[VOD_LECTURE_CONTENT].afterFigure,
	[VOD_LECTURE_USER_TARGET]: state => state[VOD_LECTURE_CONTENT].userTarget,
	[VOD_LECTURE_CONFIGURATION]: state => state[VOD_LECTURE_CONTENT].configuration,
	[VOD_LECTURE_CURRICULUMS]: state => state[VOD_LECTURE_CONTENT].curriculums,
	[VOD_LECTURE_FAQ]: state => state[VOD_LECTURE_CONTENT].faqData,
	[VOD_LECTURE_SALE_INFO]: state => state[VOD_LECTURE_SALE_INFO],
	[VOD_LECTURE_URL]: state => state[VOD_LECTURE_URL],
	[VOD_LECTURE_VOD_OFFER]: state => {
		// const isVodOffer = state[VOD_LECTURE_INFO].classChips.find(chip => chip.title === '복습VOD');
		// return Boolean(isVodOffer);
		return Boolean(state);
	},
	[VOD_LECTURE_RELATION_LIST]: state => state[VOD_LECTURE_RELATION_LIST],
	[VOD_LECTURE_IS_PAID]: state => state[VOD_LECTURE_IS_PAID],
	[KDC_NOW_PROGRESSING]: state => state[KDC_NOW_PROGRESSING],
	[IS_LOGIN_MODAL_VISIBLE]: state => state[IS_LOGIN_MODAL_VISIBLE],
	[VOD_LECTURE_PURCHASE_TIME]: state => state[VOD_LECTURE_PURCHASE_TIME],
	[KDC_CHECK_CARD_MODAL]: state => state[KDC_CHECK_CARD_MODAL],
	[KDC_SELF_AUTH_CHECK]: state => state[KDC_SELF_AUTH_CHECK],
	[IS_KDC_USER]: state => state[IS_KDC_USER],
	[IS_KDC_CLASS]: state => state[VOD_LECTURE_INFO].type === 'kdc',
	[OPEN_KDC_REDIRECT_MODAL]: state => state[OPEN_KDC_REDIRECT_MODAL],
	[VOD_DETAIL_TAB_INDEX]: state => state[VOD_DETAIL_TAB_INDEX],
	[IS_KDC_TAB_SELECTED]: state => state[VOD_DETAIL_TAB_INDEX] === 0,
};

export const mutations = {
	SET_VOD_LECTURE_ID: (state, payload) => {
		state[VOD_LECTURE_ID] = payload;
	},
	SET_VOD_LECTURE_INFO: (state, payload) => {
		state[VOD_LECTURE_INFO] = payload;
	},
	SET_VOD_LECTURE_CONTENT: (state, payload) => {
		state[VOD_LECTURE_CONTENT] = payload;
	},
	SET_VOD_LECTURE_SALE_INFO: (state, payload) => {
		state[VOD_LECTURE_SALE_INFO] = payload;
	},
	SET_VOD_LECTURE_URL: (state, payload) => {
		state[VOD_LECTURE_URL] = payload;
	},
	SET_VOD_LECTURE_RELATION_LIST: (state, payload) => {
		state[VOD_LECTURE_RELATION_LIST] = payload;
	},
	SET_VOD_LECTURE_IS_PAID: (state, payload) => {
		state[VOD_LECTURE_IS_PAID] = payload;
	},
	SET_LOGIN_MODAL_VISIBLE: (state, payload) => (state[IS_LOGIN_MODAL_VISIBLE] = payload),
	SET_VOD_LECTURE_PURCHASE_TIME: (state, payload) => (state[VOD_LECTURE_PURCHASE_TIME] = payload),
	SET_KDC_CHECK_CARD_MODAL: (state, payload) => {
		state[KDC_CHECK_CARD_MODAL] = payload;
	},
	SET_KDC_SELF_AUTH_CHECK: (state, payload) => {
		state[KDC_SELF_AUTH_CHECK] = payload;
	},
	SET_IS_KDC_USER: (state, payload) => {
		state[IS_KDC_USER] = payload;
	},
	SET_OPEN_KDC_REDIRECT_MODAL: (state, payload) => {
		state[OPEN_KDC_REDIRECT_MODAL] = payload;
	},
	SET_KDC_NOW_PROGRESSING: (state, payload) => {
		state[KDC_NOW_PROGRESSING] = payload;
	},
	SET_VOD_DETAIL_TAB_INDEX: (state, payload) => {
		state[VOD_DETAIL_TAB_INDEX] = payload;
	},
};

export const actions = {
	[APPLY_LECTURE]({ getters, commit, rootGetters }) {
		const isUser = rootGetters.isUser;
		if (!isUser) {
			return commit(SET_LOGIN_MODAL_VISIBLE, true);
		}
		$nuxt.$router.push(`${VOD_APPLY_LECTURE_PATH}/${getters[VOD_LECTURE_ID]}`);
	},
};
