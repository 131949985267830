import { APP_USER_AGENT_REGEX } from '../constants/app';
import { cdnBaseUrl, baseUrl, LOGIN_TOAST_KEY } from '../plugins/constants';

export function getBaseUrl() {
	const BASE_URL_LIST = {
		local: 'LOCAL_BASE_URL',
		develop: 'DEVELOP_BASE_URL',
		stage: 'STAGE_BASE_URL',
		qa: 'QA_BASE_URL',
		production: 'BASE_URL',
	};
	console.log('baseUrl : ', process.env[BASE_URL_LIST[process.env.NODE_ENV]]);
	return process.env[BASE_URL_LIST[process.env.NODE_ENV]];
}

export function insertIsLoadingProperty(fileList) {
	return fileList.map(file => {
		return {
			file,
			isLoading: false,
		};
	});
}

export function getCdnFilePath(path, filename, index, device = null) {
	return `${cdnBaseUrl}${path}/${filename}-${index}${(device && '-') || ''}${device || ''}.jpg`;
}

/**
 * 객체에 키값이 있는지 판별
 * @param obj: Object
 * @param propertyName: String
 * @return Boolean
 **/
export function checkOwnProperty(obj, propertyName) {
	return !!(
		obj &&
		(typeof obj === 'object' || typeof obj === 'function') &&
		Object.prototype.hasOwnProperty.call(obj, propertyName)
	);
}

/**
 * IE11이고 Windows 10인지 체크
 * @return Boolean
 **/
export function checkIE11AndWindows10() {
	return document.documentMode === 11 && navigator.userAgent.includes('Windows NT 10.0');
}

/**
 * IE11이고 Windows 10일 때는 Edge 새창으로 열기
 * @param obj: Object
 * @param propertyName: String
 * @return Boolean
 **/
export function openURLOnEdge(url) {
	window.open(`microsoft-edge:${url}`);
}

/**
 * 빈값인지 체크
 * @param value
 * @return Boolean
 **/
export function isEmptyData(value) {
	// https://sanghaklee.tistory.com/58 [이상학의 개발블로그]
	return (
		value === null ||
		typeof value === 'undefined' ||
		(typeof value === 'string' && value === '') ||
		(Array.isArray(value) && value.length < 1) ||
		(typeof value === 'object' &&
			value.constructor.name === 'Object' &&
			Object.keys(value).length < 1 &&
			Object.getOwnPropertyNames(value) < 1) ||
		(typeof value === 'object' && value.constructor.name === 'String' && Object.keys(value).length < 1)
	);
}

/**
 * nuxt 외부 페이지로 이동
 * @param path
 **/
export function moveToLaravelPage(path) {
	window.location.href = `${baseUrl}${path}`;
}

/**
 * sessionStorage에서 item get
 * @param key
 * @return Object
 **/
export function getSessionStorage(key) {
	try {
		if (process.client) {
			return JSON.parse(sessionStorage.getItem(key));
		} else {
			return null;
		}
	} catch (e) {
		return null;
	}
}

/**
 * sessionStorage에 item 저장
 * @param key
 * @param value
 **/
export function setSessionStorage(key, value) {
	try {
		// value가 어떤 자료형이든 stringify해서 넣는다.
		// 그래야 꺼낼 때 JSON.parse 안에 들어가면 이슈가 없다.
		if (process.client) {
			return sessionStorage.setItem(key, JSON.stringify(value));
		} else {
			return null;
		}
	} catch (e) {
		return null;
	}
}

/**
 * localStorage에서 item get
 * @param key
 * @return Object
 **/
export function getLocalStorage(key) {
	try {
		if (process.client) {
			return JSON.parse(localStorage.getItem(key));
		} else {
			return null;
		}
	} catch (e) {
		return null;
	}
}

/**
 * localStorage에 item 저장
 * @param key
 * @param value
 **/
export function setLocalStorage(key, value) {
	try {
		if (process.client) {
			return localStorage.setItem(key, JSON.stringify(value));
		} else {
			return null;
		}
	} catch (e) {
		return null;
	}
}

/**
 * sessionStorage에 item 제거
 * @param key
 **/
export function removeSessionStorage(key) {
	try {
		// eslint-disable-next-line no-unused-expressions
		process.client ? sessionStorage.removeItem(key) : null;
	} catch (e) {
		return null;
	}
}

/**
 * localStorage에 item 제거
 * @param key
 **/
export function removeLocalStorage(key) {
	try {
		// eslint-disable-next-line no-unused-expressions
		process.client ? localStorage.removeItem(key) : null;
	} catch (e) {
		return null;
	}
}

/**
 * 전화번호 형식으로 변환
 * @param string
 * @return string
 **/
export function phoneNumberFormat(value) {
	return value
		.replace(/[^0-9]/g, '')
		.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/, '$1-$2-$3')
		.replace('--', '-');
}

/**
 * url params parsing
 * @return object
 **/
export function getUrlParams() {
	const params = {};
	if (process.client) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (str, key, value) => {
			params[key.toLowerCase()] = decodeURIComponent(value);
		});
	}
	return params;
}

/**
 * remove baseUrl and return path
 * @return string
 **/
export function removeBaseUrl(url) {
	try {
		const urlObject = new URL(url, window.location.origin);
		return urlObject.pathname + urlObject.search + urlObject.hash;
	} catch (error) {
		return url;
	}
}

/**
 * 클립보드에 복사
 * @param text: 복사할 text
 **/
export function copyToClipboard(text) {
	const textarea = document.createElement('textarea');
	textarea.value = text;
	document.body.appendChild(textarea);
	textarea.select();
	textarea.setSelectionRange(0, 9999);
	document.execCommand('copy');
	document.body.removeChild(textarea);
}

/**
 * base64로 인코딩
 * @return String
 */
export function encodeToBase64(value) {
	return process.client ? btoa(value) : Buffer.from(String(value), 'utf8').toString('base64');
}

/**
 * base64 decode
 * @return String
 *
 * null ->ée
 * '' -> ''
 * undefined -> error
 */
export function decodeBase64(value) {
	return process.client ? atob(value) : Buffer.from(String(value), 'base64').toString('utf8');
}

/**
 * json -> array
 * @param json: json 데이터
 * @return Array
 */
export function jsonToArray(json) {
	let result = [];
	const keys = Object.keys(json);
	keys.forEach(function (key) {
		result = [...result, json[key]];
	});
	return result;
}

export function unescapeHTML(string = '') {
	return string.replace(
		/&amp;|&lt;|&gt;|&#39;|&quot;/g,
		tag =>
			({
				'&amp;': '&',
				'&lt;': '<',
				'&gt;': '>',
				'&#39;': "'",
				'&quot;': '"',
			}[tag] || tag),
	);
}

export function toggleInArray(array, value) {
	const valueIndex = array.indexOf(value);
	const copiedArray = [...array];
	if (valueIndex > -1) {
		copiedArray.splice(valueIndex, 1);
	} else {
		copiedArray.push(value);
	}

	return copiedArray;
}

export function isKoreanCharacter(char) {
	const charCode = char.charCodeAt(0);
	if (charCode >= 0x1100 && charCode <= 0x11ff) {
		return true;
	}
	if (charCode >= 0x3130 && charCode <= 0x318f) {
		return true;
	}
	return charCode >= 0xac00 && charCode <= 0xd7a3;
}

export function downloadFile(data, { fileName }) {
	const url = window.URL.createObjectURL(new Blob([data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
}

// 로그인 토스트 생성
export function setLoginToast({ message, type, position }) {
	setSessionStorage(LOGIN_TOAST_KEY, { message, type, position });
}

/** 정규식으로 앱체크 **/
export function checkIsApp(userAgent) {
	return String(userAgent).match(APP_USER_AGENT_REGEX) !== null;
}

export function getAppVersion(userAgent) {
	const result = String(userAgent).match(APP_USER_AGENT_REGEX);
	return result[result.length - 1];
}

export function getAppOS(userAgent) {
	const result = String(userAgent).match(APP_USER_AGENT_REGEX);
	return result[1].toLowerCase();
}

export function getFormattedPhoneNumber(number) {
	return number
		.replace(/[^0-9]/g, '')
		.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/, '$1-$2-$3')
		.replace('--', '-');
}

// 공백 제거
export function removeWhiteSpace(value) {
	return value.replace(/\s/gi, '');
}

export function debounce(func, delay) {
	let timeout;
	return function () {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const context = this;
		const args = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(context, args), delay);
	};
}

export function throttle(func, delay) {
	let wait = false;

	return function () {
		if (wait) {
			return;
		}

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const context = this;
		const args = arguments;
		func.apply(context, args);
		wait = true;
		setTimeout(() => {
			wait = false;
		}, delay);
	};
}

/**
 * 한글, 영어, 숫자를 제외한 문자를 치환하는 함수
 * @param keyword
 * @returns {*}
 */
export function encodeKeyword(keyword) {
	const regex = /[^\wㄱ-힣()0-9]/gi;
	return keyword.replace(new RegExp(regex), match => encodeURIComponent(match));
}

/**
 * 호스트 부분을 제거한 URL을 리턴
 * @param {string} url URL
 * @returns 호스트가 제거된 URL
 */
export function getUrlWithoutHost(url) {
	try {
		const { pathname, search } = new URL(url);
		return pathname + search;
	} catch (error) {
		console.warn('Not an URL');
		return url;
	}
}

/**
 * 주어진 선택자가 유효한지 확인하기 위해 문서 프래그먼트에서 쿼리를 시도합니다.
 *
 * @param {string} selector - 확인할 CSS 선택자입니다.
 * @return {boolean} 선택자가 유효하면 true, 그렇지 않으면 false입니다.
 */
export function isSelectorValid(selector) {
	try {
		document.createDocumentFragment().querySelector(selector);
	} catch (error) {
		return false;
	}

	return true;
}

// base <= target 판별
export function compareVersion(base, target) {
	base = base.split('.');
	target = target.split('.');

	const length = Math.max(base.length, target.length);

	for (let i = 0; i < length; i++) {
		const b = base[i] ? parseInt(base[i], 10) : 0;
		const t = target[i] ? parseInt(target[i], 10) : 0;
		if (b !== t) {
			return b < t;
		}
	}
	// 똑같을 경우
	return true;
}

/**
 * 문자열 치환
 * @param {string} variable
 * @param {string} value
 * @param {string} newValue
 * @returns {*}
 */
export function replaceWord(variable, value, newValue) {
	const regex = new RegExp(value, 'gi');
	return variable?.replace(regex, newValue);
}

export const convertBooleanToYorN = value => (value ? 'Y' : 'N');

export const isHardRefresh = context => context.req?.headers['cache-control'] === 'no-cache';

export const isKakaoTalkInAppBrowser = () => {
	// User-Agent 문자열을 소문자로 변환하여 검사
	const userAgent = navigator.userAgent.toLowerCase();

	// "kakaotalk" 문자열이 포함되어 있는지 확인
	return userAgent.includes('kakaotalk');
};

export const closeBrowser = url => {
	window.location.href = url;
};

export const isAndroid = () => {
	const userAgent = navigator.userAgent.toLowerCase();
	return userAgent.includes('android');
};

export const isIOS = () => {
	const userAgent = navigator.userAgent.toLowerCase();
	return userAgent.includes('iphone') || userAgent.includes('ipad');
};

export const closeKakaoTalkInAppBrowserInAndroid = () => {
	closeBrowser('kakaotalk://inappbrowser/close');
};

export const closeKakaoTalkInAppBrowserInIOS = () => {
	closeBrowser('kakaoweb://closeBrowser');
};

export const closeWindow = () => {
	if (isKakaoTalkInAppBrowser()) {
		// 카카오톡 인앱 브라우저에서는 window.close()가 동작하지 않아서 다음과 같이 처리
		// android
		if (isAndroid()) {
			closeKakaoTalkInAppBrowserInAndroid();
			return;
		}
		// ios
		if (isIOS()) {
			closeKakaoTalkInAppBrowserInIOS();
			return;
		}
	}
	window.close();
};
