
import MyPageSettingProfileGradeProgressBar from '@/components/my-page/setting/profile/atom/MyPageSettingProfileGradeProgressBar.vue';
import MypageSettingProfileGradeCheckList from '@/components/my-page/setting/profile/molecules/MypageSettingProfileGradeCheckList.vue';
import MypageSettingProfileGradePromotionCriteriaTable from '@/components/my-page/setting/profile/molecules/MypageSettingProfileGradePromotionCriteriaTable.vue';

import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'MyPageSettingProfileGradeModal',
	mixins: [layoutMixin],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		myGradeInfo: {
			type: Object,
			default: () => {},
		},
		gradeProgress: {
			type: Number,
			default: 0,
		},
		isLoadedProfileInfo: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		sync_show: {
			get() {
				return this.show;
			},
			set(value) {
				this.$emit('update:show', value);
			},
		},
		remainGradeProgress() {
			return 100 - this.gradeProgress;
		},
	},

	methods: {
		// closeGradeModal() {
		// 	this.sync_show = false;
		// },
	},
	components: {
		MypageSettingProfileGradePromotionCriteriaTable,
		MypageSettingProfileGradeCheckList,
		MyPageSettingProfileGradeProgressBar,
	},
};
