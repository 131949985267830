import { render, staticRenderFns } from "./AnswerSideBox.vue?vue&type=template&id=b6704df0&scoped=true&"
import script from "./AnswerSideBox.vue?vue&type=script&lang=js&"
export * from "./AnswerSideBox.vue?vue&type=script&lang=js&"
import style0 from "./AnswerSideBox.vue?vue&type=style&index=0&id=b6704df0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6704df0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnswerSideBoxMissionExp: require('/app/components/answer/molecules/AnswerSideBoxMissionExp.vue').default,AnswerSideBoxCreditShop: require('/app/components/answer/molecules/AnswerSideBoxCreditShop.vue').default})
