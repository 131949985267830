const ANSWER_PREFIX_URL = `/api/answer`;

export default axios => ({
	/**
	 * 답변 가능한 글인지 판단
	 */
	postCheckAnswer(data) {
		return axios.post(`${ANSWER_PREFIX_URL}/check-answer`, data);
	},
	/**
	 * 답변하기 리스트 가져오기
	 * @apiParam {Integer} page 페이지 번호
	 * @apiParam {Integer} filter_request_council 0,1
	 * @apiParam {Integer} filter_request_essay 0,1
	 * @apiParam {Integer} filter_request_interview 0,1
	 * @apiParam {String} keyword
	 */
	getAnswerList(params, cache) {
		return axios.get(`${ANSWER_PREFIX_URL}/getAnswer2`, { params, cache });
	},
	/**
	 *
	 * @param {number} params.page 페이지 번호
	 * @param {number} params.qestion
	 * @param {number} params.essay
	 * @param {number} params.interview
	 * @param {string} params.keyword
	 * @param {string} params.sort
	 * @returns {*}
	 */
	getAnswerSearchList(params) {
		return axios.get(`/api/v2/search/answer`, { params });
	},
	/**
	 * 신규멘토질문 답변하기 리스트 가져오기
	 * @apiParam {Integer} filter_request_council 0,1
	 */
	getMentoringForFirstTime(params) {
		return axios.get(`${ANSWER_PREFIX_URL}/getMentoringForFirstTime`, { params, cache: true });
	},
	/**
	 * 리스트 상담사례 삭제
	 * @apiParam {Integer} request_no 글번호
	 * @apiParam {String} type '', re_mentoring
	 */
	postRemoveAnswer(data) {
		return axios.post(`${ANSWER_PREFIX_URL}/removeAnswer`, data);
	},
	/**
	 * 상담사례 최신순
	 * @apiParam {Integer} page 페이지 번호
	 * @apiParam {Integer} limit 페이지당 아이템 개수
	 * @apiParam {String} keyword 검색어
	 * @apiParam {Integer} category_group_id 서브 헤더 카테고리 그룹 고유번호
	 * @apiParam {Integer} category_id 카테고리 고유번호 [nullable]
	 */
	getCaseDetailMetaDescription(data) {
		return axios.post(`${ANSWER_PREFIX_URL}/getMetaDescription`, data);
	},
	/**
	 * 유저정보 관련성, 멘토링 키워드 가져오기
	 */
	getUserInfo() {
		return axios.get(`${ANSWER_PREFIX_URL}/userInfo`);
	},
	/**
	 * 멘토 답변하기 미션
	 */
	getUserAnswerMission() {
		return axios.post('/api/mission/user/state');
	},
	/**
	 * 멘토 멘토링 랭킹
	 */
	getUserAnswerRank() {
		return axios.get('/api/rank/best');
	},
	/**
	 * 현직자 전환 감사메세지 대상자 확인
	 */
	getCheckCanThanksMessage() {
		return axios.get('/api/mypage/checkCanThanksMessage');
	},
	/**
	 * 현직자 전환 감사메세지 보내기
	 * @apiParam {String} thanks_message 감사인사 말
	 */
	setThanksMessage(data) {
		return axios.post(`/api/mypage/setThanksMessage`, data);
	},
	/**
	 * 답변하기 리스트에 보여줄 크레딧샵 리스트
	 * @returns {*}
	 */
	getCreditShopList() {
		return axios.get('api/giftishow/gifticon-list');
	},
});
