import partition from 'lodash/partition';
import { createNamespacedHelpers } from 'vuex';

import dayjs, { getTimeInAsia } from '@/plugins/dayjs';

const NAMESPACE = 'training';
export { NAMESPACE as NAMESPACE_TRAINING };
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfTraining,
	mapMutations as mapMutationsOfTraining,
	mapActions as mapActionsOfTraining,
};

export const FILTERED_TRAINING_LIST = 'FILTERED_TRAINING_LIST';
export const TRAINING_DETAIL_ITEM = 'TRAINING_DETAIL_ITEM';
export const IS_LEGACY_VERSION = 'IS_LEGACY_VERSION';

export const SET_TRAINING_LIST = 'SET_TRAINING_LIST';
export const SET_TRAINING_DETAIL_ITEM = 'SET_TRAINING_DETAIL_ITEM';

export const LOAD_TRAINING_LIST = 'LOAD_TRAINING_LIST';
export const LOAD_TRAINING_DETAIL_ITEM_FROM_NOTION = 'LOAD_TRAINING_DETAIL_ITEM_FROM_NOTION';
export const LOAD_TRAINING_DETAIL_ITEM_FROM_BASEROW = 'LOAD_TRAINING_DETAIL_ITEM_FROM_BASEROW';
export const RESET_TRAINING_DETAIL_ITEM = 'RESET_TRAINING_DETAIL_ITEM';

export const state = () => ({
	trainingList: [],
	trainingDetailItem: {},
});

export const getters = {
	[FILTERED_TRAINING_LIST]: state => {
		const list = state.trainingList;
		const [active, inactive] = partition(list, item =>
			getTimeInAsia().isSameOrBefore(dayjs(item.recruitEndDate).endOf('day')),
		);

		active.sort((a, b) => {
			return dayjs(a.recruitEndDate).isSameOrBefore(dayjs(b.recruitEndDate)) ? -1 : 1;
		});

		return [...active, ...inactive];
	},
	[TRAINING_DETAIL_ITEM]: state => state.trainingDetailItem,
	[IS_LEGACY_VERSION]: state => state.trainingDetailItem?.version === '1',
};

export const mutations = {
	[SET_TRAINING_LIST](state, payload) {
		state.trainingList = payload;
	},
	[SET_TRAINING_DETAIL_ITEM](state, payload) {
		if (!payload) {
			return;
		}
		return (state.trainingDetailItem = { ...state.trainingDetailItem, ...payload });
	},
};

export const actions = {
	async [LOAD_TRAINING_LIST]({ commit }) {
		const params = {
			filter__status__equal: Number(true),
		};
		const { data } = await this.$api.baserow.getSeSACList(params);
		commit(SET_TRAINING_LIST, data);
	},
	async [LOAD_TRAINING_DETAIL_ITEM_FROM_BASEROW]({ commit }, partnerWithRound) {
		const params = {
			partnerWithRound,
		};
		const { data } = await this.$api.baserow.getSeSACDetail(params);
		commit(SET_TRAINING_DETAIL_ITEM, { sections: data });
	},
	async loadTrainingDetailItemFromNotion(_, partnerWithRound) {
		const { data } = await this.$api.notion.getClassTrainingDetail({ name: partnerWithRound });
		return data.results
			.filter(({ properties: { name } }) => name.title[0]?.plain_text === partnerWithRound)
			.map(
				({
					properties: {
						foreign_id,
						name_ko,
						dominant_color,
						education_start_date,
						education_end_date,
						education_date_description,
						benefit_title,
						education_place,
						skills_title,
						skills_sub_title,
						target_title,
						instructors_title,
						review_title,
						advantage_title,
						blog_url,
						portfolio_url,
						contact_url,
						curriculum_url,
						curriculum_title,
						after_benefit_title,
						apply_info_title,
						apply_button_text,
						banner_sub_title,
						faq,
					},
				}) => {
					return {
						name_ko: name_ko.rich_text[0]?.plain_text,
						foreign_id: foreign_id.relation[0].id,
						dominant_color: dominant_color.rich_text[0]?.plain_text,
						education_start_date: education_start_date.rich_text[0]?.plain_text,
						education_end_date: education_end_date.rich_text[0]?.plain_text,
						education_date_description: education_date_description.rich_text[0]?.plain_text,
						benefit_title: benefit_title.rich_text[0]?.plain_text,
						education_place: education_place.rich_text[0]?.plain_text,
						skills_title: skills_title.rich_text[0]?.plain_text,
						skills_sub_title: skills_sub_title.rich_text[0]?.plain_text,
						target_title: target_title.rich_text[0]?.plain_text,
						instructors_title: instructors_title.rich_text[0]?.plain_text,
						review_title: review_title.rich_text[0]?.plain_text,
						advantage_title: advantage_title.rich_text[0]?.plain_text,
						blog_url: blog_url.url,
						portfolio_url: portfolio_url.url,
						contact_url: contact_url.url,
						curriculum_url: curriculum_url.url,
						curriculum_title: curriculum_title.rich_text[0]?.plain_text,
						after_benefit_title: after_benefit_title.rich_text[0]?.plain_text,
						apply_info_title: apply_info_title.rich_text[0]?.plain_text,
						apply_button_text: apply_button_text.rich_text[0]?.plain_text,
						banner_sub_title: banner_sub_title.rich_text[0]?.plain_text,
						faq: faq.relation.map(({ id }) => id),
					};
				},
			);
	},
	async loadTrainingDetailFAQ() {
		try {
			const { data } = await this.$api.notion.getClassTrainingDetailFAQ();
			return data.results.map(({ id, properties: { question, answer } }) => {
				return { id, question: question.title[0].plain_text, answer: answer.rich_text[0]?.plain_text };
			});
		} catch (error) {
			console.error('loadTrainingDetailFAQ error: ', error);
		}
	},
	async [LOAD_TRAINING_DETAIL_ITEM_FROM_NOTION]({ commit, dispatch }, partnerWithRound) {
		if (!partnerWithRound) {
			return;
		}
		const detail = await dispatch('loadTrainingDetailItemFromNotion', partnerWithRound);
		const faqIdList = await dispatch('loadTrainingDetailFAQ');
		const filteredFAQList = detail[0].faq.map(faqId => {
			return faqIdList.find(faq => faq.id === faqId);
		});
		commit(SET_TRAINING_DETAIL_ITEM, { ...detail[0], faq: filteredFAQList });
	},
	[RESET_TRAINING_DETAIL_ITEM]({ commit }) {
		commit(SET_TRAINING_DETAIL_ITEM, {});
	},
};
