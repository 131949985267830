
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'MypageSettingProfileGradePromotionCriteriaTable',
	data() {
		return {
			heads: Object.freeze(['Level', '직급', '누적 크레딧', '채택률', '인상률']),
			grades: Object.freeze([
				{
					level: 1,
					grade: '코인턴',
				},
				{
					level: 2,
					grade: '코사원',
				},
				{
					level: 3,
					grade: '코주임',
					credit: 3000,
					increaseRate: 0.5,
				},
				{
					level: 4,
					grade: '코대리',
					credit: 6000,
					increaseRate: 1,
				},
				{
					level: 5,
					grade: '코과장',
					credit: 30000,
					increaseRate: 3,
				},
				{
					level: 6,
					grade: '코차장',
					credit: 100000,
					increaseRate: 5,
				},

				{
					level: 7,
					grade: '코부장',
					credit: 250000,
					increaseRate: 7,
				},
				{
					level: 8,
					grade: '코이사',
					credit: 500000,
					choiceRate: 50,
					increaseRate: 9,
				},
				{
					level: 9,
					grade: '코상무',
					credit: 1000000,
					choiceRate: 55,
					increaseRate: 11,
				},
				{
					level: 10,
					grade: '코전무',
					credit: 1700000,
					choiceRate: 58,
					increaseRate: 13,
				},
				{
					level: 11,
					grade: '코부사장',
					credit: 2500000,
					choiceRate: 60,
					increaseRate: 15,
				},
				{
					level: 12,
					grade: '코사장',
					credit: 5000000,
					choiceRate: 65,
					increaseRate: 20,
				},
			]),
		};
	},
	methods: {
		convertRateToPercent(rate) {
			if (!rate) {
				return null;
			}
			return `${rate}%`;
		},
		displayValueOrDash(input) {
			return input || '-';
		},
	},
});
