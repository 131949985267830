export const CLASS_TYPE = {
	LIVE: 'live',
	VOD: 'vod',
	VOD_ONLY: 'vod_only',
};

export const CLASS_TYPE_TITLE = {
	LIVE: 'LIVE',
	VOD: 'VOD+LIVE',
	VOD_ONLY: '실무 VOD',
};

export const CLASS_PT_ACTION_BUTTON_TEXT_WHEN_CLOSED = '모집 마감';
export const CLASS_PT_RELATION_CLASS_TITLE_WHEN_CLOSED = '이런 클래스는 어때요?';

export const SUPER_EARLY_BIRD = 'superEarlyBird';

export const CLASS_PT_SALE_TYPES = {
	[SUPER_EARLY_BIRD]: '슈퍼 얼리버드',
};

export const CLASS_PT = '실무PT';
export const CLASS_NAME_IN_CLASS_PT = '트레이닝';

export const CLASS_PT_ENCORE_TEXT = '차 앵콜';
