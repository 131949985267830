import { render, staticRenderFns } from "./MyPageInfoBoard.vue?vue&type=template&id=753c0c78&scoped=true&"
import script from "./MyPageInfoBoard.vue?vue&type=script&lang=js&"
export * from "./MyPageInfoBoard.vue?vue&type=script&lang=js&"
import style0 from "./MyPageInfoBoard.vue?vue&type=style&index=0&id=753c0c78&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "753c0c78",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MyPageThanksInfoBoard: require('/app/components/my-page/thanks/molecules/MyPageThanksInfoBoard.vue').default,MyPageCreditInfoBoard: require('/app/components/my-page/_main/atom/MyPageCreditInfoBoard.vue').default,MyPageCashInfoBoard: require('/app/components/my-page/_main/atom/MyPageCashInfoBoard.vue').default,MyPageEssayInfoBoard: require('/app/components/my-page/_main/atom/MyPageEssayInfoBoard.vue').default})
