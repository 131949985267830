export default axios => ({
	/**
	 * 크레딧샵 리스트
	 */
	fetchGiftishowList() {
		return axios.get(`/api/giftishow/list`);
	},
	/**
	 * 크레딧샵 상품 정보
	 * @param data
	 * @returns {*}
	 */
	fetchShopItem(data) {
		return axios.post('/api/giftishow/giftishowGood', data);
	},
	/**
	 * 현금 상품 정보
	 * @param {Object} data
	 * @param {string} data.goods_id - 상품 ID
	 * @returns {Promise}
	 */
	fetchCashInfo(data) {
		return axios.post('/api/giftishow/giftishowCash', data);
	},
	/**
	 * 기프티쇼 상품 구매
	 * @param {Object} data
	 * @param {string} data.user_no - 유저번호
	 * @param {string} data.goods_id - 상품 ID
	 * @param {string} data.phone - 전화번호
	 * @returns {Promise}
	 */
	exchangeGoods(data) {
		return axios.post('/api/giftishow/request', data);
	},
	/**
	 * 현금 환급 개인정보 가져오기
	 */
	fetchCashLogLastInfo() {
		return axios.post('/api/credit/cashLogLastInfo');
	},
	/**
	 * 현금 환급 신청
	 * @param {Object} data
	 * @param {string} data.user_no - 유저번호
	 * @param {string} data.goods_id - 상품 ID
	 * @param {string} data.phone - 전화번호
	 * @param {string} data.account_name - 예금주
	 * @param {string} data.account_bank - 입금 은행
	 * @param {string} data.account_account - 계좌번호
	 * @param {string} data.local - 내/외국인(1: 내국인, 0: 외국인)
	 * @param {string} data.ssn1 - 주민번호 앞자리
	 * @param {string} data.ssn2 - 주민번호 뒷자리
	 * @returns {Promise}
	 */
	exchangeCash(data) {
		return axios.post('/api/giftishow/requestCash', data);
	},
});
