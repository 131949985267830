import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'answer/creditShop';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfCreditShop,
	mapMutations as mapMutationsOfCreditShop,
	mapActions as mapActionsOfCreditShop,
};

export const CREDIT_SHOP_LIST = 'CREDIT_SHOP_LIST';
export const IS_OPEN_CREDIT_SHOP_LIST = 'IS_OPEN_CREDIT_SHOP_LIST';

export const SET_CREDIT_SHOP_LIST = 'SET_CREDIT_SHOP_LIST';
export const TOGGLE_IS_OPEN_CREDIT_SHOP_LIST = 'TOGGLE_IS_OPEN_CREDIT_SHOP_LIST';

export const LOAD_CREDIT_SHOP_LIST = 'LOAD_CREDIT_SHOP_LIST';

export const state = () => ({
	creditShopList: [],
	isOpenCreditShopList: false,
});

export const getters = {
	[CREDIT_SHOP_LIST]: state => state.creditShopList,
	[IS_OPEN_CREDIT_SHOP_LIST]: state => state.isOpenCreditShopList,
};

export const mutations = {
	[SET_CREDIT_SHOP_LIST](state, payload) {
		state.creditShopList = payload;
	},
	[TOGGLE_IS_OPEN_CREDIT_SHOP_LIST](state) {
		state.isOpenCreditShopList = !state.isOpenCreditShopList;
	},
};

export const actions = {
	async [LOAD_CREDIT_SHOP_LIST]({ commit }) {
		try {
			const { data } = await this.$api.answerList.getCreditShopList();
			commit(SET_CREDIT_SHOP_LIST, data);
		} catch (error) {
			console.error(error);
		}
	},
};
