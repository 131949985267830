import { baseUrl } from '@/plugins/constants';

const VOD_LECTURE_API_PREFIX = `/api/vod-lecture`;

export default axios => ({
	/**
	 * VOD detail info, 인앱 금액 처리
	 * @params id, isApp, isAppPG
	 */
	async getVodDetailInfo(requestData, isApp, isAppPG) {
		try {
			const { data } = await this.fetchVodDetailInfo(requestData);
			if (!isApp || (isApp && isAppPG)) {
				return { data };
			}
			const pricing = data.pricing;
			return {
				data: {
					...data,
					pricing: {
						...pricing,
						blindEarlyBird: 0,
						earlyBird1: 0,
						earlyBird2: 0,
						superEarlyBird: 0,
						price: pricing.price,
					},
				},
			};
		} catch (error) {
			console.error(error);
		}
	},
	async getVodDetailInfoV2(requestData) {
		try {
			return await this.fetchVodDetailInfoV2(requestData);
		} catch (error) {
			console.error(error);
		}
	},
	/**
	 * VOD detail info 가져오기
	 * @params id
	 */
	fetchVodDetailInfo(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/detail/info`, data);
	},
	/**
	 * VOD detail info 가져오기 V2
	 * @params id
	 */
	fetchVodDetailInfoV2(data) {
		return axios.post('/api/kdc/detail/info', data);
	},
	fetchVodSimpleInfo(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/simple/info`, data);
	},
	/**
	 * 디테일 contents 가져오기
	 * @params id
	 */
	getVodDetailContent(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/detail/content`, data);
	},
	getVodDetailContentV2(data) {
		return axios.post('/api/kdc/detail/content', data);
	},
	/**
	 * 구매 여부 가져오기
	 */
	getIsVodPaid(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/check/paid`, data);
	},
	/**
	 * 강의 영상 목록 가져오기
	 */
	getLectureList(params) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/process/list`, { params });
	},

	/**
	 * 영상 정보 가져오기
	 */
	getLessonInfo(params) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/lesson/info`, params);
	},

	/**
	 * 시청 기록
	 * @param data { lectureId: Number, lessonId: Number, vimeo: { seconds: Number, duration: Number, percent: Number, isKdcUser: boolean } }
	 */
	postPlayerTimeUpdate(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/log/realtime`, data);
	},

	/**
	 * 시청 이벤트 기록
	 */
	postPlayerEvent(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/log/eventtime`, data);
	},

	/**
	 * 시청 중 에러 이벤트 기록
	 */
	postPlayerError(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/log/error`, data);
	},

	/**
	 * 글 목록 가져오기
	 */
	getCommunityList({ page = 1, type = 'all', lectureId }) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/community/list`, { params: { page, type, lectureId } });
	},

	/**
	 * 글 쓰기
	 */
	postCommunityList(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/community/write`, data);
	},

	/**
	 * 글 정보 가져오기
	 * @param {Number} request_no 질문 번호
	 * @returns 응답
	 */
	getCommunityDetailQuestion(request_no) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/community/detail/question`, { params: { request_no } });
	},

	/**
	 * 글 댓글 가져오기
	 * @param {Number} request_no 질문 번호
	 * @param {Number} lectureId 강의 번호
	 * @returns 응답
	 */
	getCommunityDetailAnswer(request_no, lectureId) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/community/detail/answer`, { request_no, lectureId });
	},

	/**
	 * 글 댓글 달기
	 * @param {{request_no: Number, comment: String}} data 댓글 정보
	 * @returns 응답
	 */
	postCommunityDetailComment(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/community/detail/comment`, data);
	},

	/**
	 * 글 대댓글 달기
	 * @param {{request_no: Number, comment: String, feedback_no: Number}} data 대댓글 정보
	 * @returns 응답
	 */
	postCommunityDetailReply(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/community/detail/reply`, data);
	},

	/**
	 * 커뮤니티 글 좋아요
	 */
	postCommunityDetailQuestionLike(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/community/detail/question/like`, data);
	},

	/**
	 * 커뮤니티 댓글 좋아요
	 */
	postCommunityFeedbackLike(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/community/detail/answer/like`, data);
	},

	/**
	 * 학습 노트 목록
	 * @param {{ lectureId: Number, lessonId: Number, limit: Number }} params
	 * @returns
	 */
	getNoteList(params) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/note/list`, params);
	},

	/**
	 * 노트 작성
	 * @param {Object} data 노트 작성
	 * @returns 성공 여부
	 */
	postNote(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/note/write`, data);
	},

	/**
	 * 노트 수정
	 * @param {Object} data 노트
	 * @returns 결과
	 */
	updateNote(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/note/update`, data);
	},

	/**
	 * 노트 삭제
	 * @param {Object} data 삭제할 노트
	 * @returns 성공 여부
	 */
	deleteNote(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/note/delete`, data);
	},

	/**
	 * 강의 자료실 파일 목록
	 */
	getFiles(params) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/file/list`, { params });
	},

	/**
	 * 파일 다운로드
	 * @param {{lectureId: Number, lessonId: Number, fileId: Number}} data
	 * @returns 파일
	 */
	downloadFile(params) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/file/download`, { params, responseType: 'blob' });
	},

	/**
	 * 전체 파일 다운로드
	 */
	downloadAllFiles(params) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/file/download/all`, { params, responseType: 'blob' });
	},

	/**
	 * 지원 정보 등록
	 */
	postApplicant(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/apply`, data);
	},

	/**
	 * 지원 정보 조회
	 */
	getApplicant(params) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/apply`, { params });
	},

	logDownload(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/log/download`, data);
	},
	/**
	 * vodLectureUserInfo vod kdc 카드 및 본인인증 여부 확인
	 * @params lectureId : VOD 강의 ID
	 **/
	getUserInfo(params) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/user/info`, { params });
	},
	/**
	 * KDC 과제 제출 리스트
	 */
	getKdcAssignmentList(params) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/assignment/list`, { params });
	},
	/**
	 * KDC 과제 업로드
	 */
	postSubmitAttachedFile(data) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/assignment/upload`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},
	/**
	 * 브라우저가 종료되는 시점에서 사용하는 fetch
	 * fetch의 keepalive 옵션을 쓰면 브라우저가 종료되는 시점에서도 안정적으로 전송 가능
	 * axios에는 이 옵션이 아직 없음
	 */
	postPlayerEventKeepalive(data, token) {
		fetch(`${baseUrl}${VOD_LECTURE_API_PREFIX}/log/eventtime`, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			},
			keepalive: true,
		});
	},
	/**
	 * VOD에서 신청자가 할 수 있는 과제를 불러옴
	 */
	getVodAssignments(lectureId) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/assignments`, { params: { lectureId } });
	},
	/**
	 * 제출한 과제를 삭제
	 */
	deleteAssignment(assignmentId) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/assignment/delete`, { assignmentId });
	},
	/**
	 * 보안서약서 코드가 유효한지 체크
	 * @param {string} code 보안서약서 코드
	 */
	checkCodeIsValid(code) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/security-pledge/check`, { params: { code } });
	},
	/**
	 * 보안서약서 서명 저장
	 * @param {string} code 보안서약서 코드
	 */
	signSecrecyTerms(code) {
		return axios.post(`${VOD_LECTURE_API_PREFIX}/security-pledge/confirm`, { code });
	},
	/**
	 * 보안서약서 서명했는지 확인
	 * @param {number} lectureId VOD 강의 ID
	 */
	checkUserSigned(lectureId) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/security-pledge`, { params: { lectureId } });
	},
	/**
	 * VOD 강의 LMS 페이지 - 학습현황
	 * @param {number} lectureId VOD 강의 ID
	 */
	getLectureVodDashboard(lectureId) {
		return axios.get(`${VOD_LECTURE_API_PREFIX}/dashboard`, { params: { lectureId } });
	},
});
