import axios from 'axios';

import { baseUrl, isLocal } from '@/plugins/constants';

const PREFIX = '/notion';
const nuxtBaseUrl = baseUrl + (isLocal ? ':8000' : '');
axios.defaults.baseURL = nuxtBaseUrl;

/**
 * notion api factory
 * Notion은 CORS를 위해 server-middleware 사용
 * 라라벨로 보내지 않기 위해 baseUrl 없는 기본 axios 사용
 */
export default () => ({
	getClassPtSyllabus() {
		return axios.get(`${PREFIX}/class/pt/syllabus`);
	},
	getComentoMembers() {
		return axios.get(`${PREFIX}/comento/members`);
	},
	/**
	 * 양성과정(SeSAC) 상세페이지 정보
	 * @param {foreign_id} params
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getClassTrainingDetail(params) {
		return axios.get(`${PREFIX}/class/training/detail`, { params });
	},
	/**
	 * 양성과정(SeSAC) 상세페이지 FAQ
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getClassTrainingDetailFAQ() {
		return axios.get(`${PREFIX}/class/training/faq`);
	},
	/**
	 * 드림버스 리스트
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getDreamverseList() {
		return axios.get(`${PREFIX}/dreamverse/list`);
	},
	/**
	 * 인턴즈 상세페이지 정보
	 * @param {Object} params
	 * @param {string} params.foreign_id: Notion list unique id
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getInternzDetail(params) {
		return axios.get(`${PREFIX}/internz/detail`, { params });
	},
	/**
	 * 인턴즈 회사 정보
	 * @param {Object} params
	 * @param {string} params.foreign_id: Notion list unique id
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getInternzCompany(params) {
		return axios.get(`${PREFIX}/internz/company`, { params });
	},
	/**
	 * 인턴즈 회사별 직무 정보
	 * @param {Object} params
	 * @param {string} params.foreign_id: Notion list unique id
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getInternzJobs(params) {
		return axios.get(`${PREFIX}/internz/jobs`, { params });
	},
	/**
	 * 드림버스 상세페이지 정보
	 * @param {Object} params
	 * @param {string} params.foreign_id: Notion list unique id
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getDreamverseDetail(params) {
		return axios.get(`${PREFIX}/dreamverse/detail`, { params });
	},
	/**
	 * 드림버스 모집 분야
	 * @param {Object} params
	 * @param {string} params.foreign_id: Notion list unique id
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getDreamverseJobs(params) {
		return axios.get(`${PREFIX}/dreamverse/jobs`, { params });
	},
	/**
	 * 드림버스 영상
	 * @param {Object} params
	 * @param {string} params.foreign_id: Notion list unique id
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getDreamverseVideos(params) {
		return axios.get(`${PREFIX}/dreamverse/videos`, { params });
	},
	/**
	 * 드림버스 사이트
	 * @param {Object} params
	 * @param {string} params.foreign_id: Notion list unique id
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getDreamverseSites(params) {
		return axios.get(`${PREFIX}/dreamverse/sites`, { params });
	},
	/**
	 * 드림버스 참여 제한 대상
	 * @param params
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getDreamverseNonParticipatingTarget(params) {
		return axios.get(`${PREFIX}/dreamverse/non-participating-target`, { params });
	},
	/**
	 * KDC HRD 리뷰 모음
	 * @param {Object} params
	 * @param {string} params.id: KDC 강의 ID
	 */
	getHrdReviews(params) {
		return axios.get(`${PREFIX}/hrd/reviews`, { params });
	},
	getWoowaDashboardApplicant() {
		return axios.get(`${PREFIX}/woowa-dashboard/applicant`);
	},
	getWoowaDashboardClass() {
		return axios.get(`${PREFIX}/woowa-dashboard/class`);
	},
	getPipedriveWebForms(params) {
		return axios.get(`${PREFIX}/pipedrive/web-forms`, { params });
	},
});
