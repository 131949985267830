import { mapGetters } from 'vuex';

import { MY_PAGE_MENUS } from '@/constants/my-page';
import { RESUME_INDEX_PATH, CS_PATH, NOTICE_PATH } from '@/constants/paths';
import {
	MY_PAGE_PATH,
	MY_PAGE_THANKS_PATH,
	MY_PAGE_SETTING_PATH,
	MY_PAGE_SETTING_QUIT_PATH,
	MY_PAGE_CLASSROOM_PATH,
	MY_PAGE_PURCHASE_HISTORY_PATH,
	MY_PAGE_COMMUNITY_PATH,
	MY_PAGE_CREDIT_SHOP_PATH,
	MY_PAGE_CREDIT_CASH_PATH,
	MY_PAGE_CREDIT_LIST_PATH,
	MY_PAGE_ESSAY_PATH,
	MY_PAGE_AI_ANALYTICS_PATH,
	MY_PAGE_CLASSROOM_NAV,
	// MY_PAGE_PURCHASE_NAV,
	MY_PAGE_COMMUNITY_NAV,
	MY_PAGE_ALL_NAME,
	MY_PAGE_NAV_PREFIX,
	MY_PAGE_SETTING_NAME,
	MY_PAGE_SETTING_CAREER_NAME,
	MY_PAGE_SETTING_NOTI_NAME,
	MY_PAGE_SETTING_QUIT_NAME,
	MY_PAGE_SETTING_NOTI_PC_PATH,
	MY_PAGE_SETTING_CAREER_PC_PATH,
	MY_PAGE_CREATE_CLASS_PATH,
	MY_PAGE_CREATE_CLASS_NAV,
	MY_PAGE_CLASSROOM_OPENED_PATH,
	MY_PAGE_CLASSROOM_APPLIED_PATH,
} from '@/constants/paths/my-page';

import { baseUrl } from '@/plugins/constants';

import { GET_HAS_REJECTION_NOTICE, mapGettersOfClassPtOpen } from '@/store/class/pt/open';
import { GET_IS_KDC_PROGRESS_EXIST, mapGettersOfKdc } from '@/store/kdc';

import layoutMixin from '@/mixins/layoutMixin';
import scrollMixin from '@/mixins/scrollMixin';

const myPageMixin = {
	mixins: [layoutMixin, scrollMixin],
	computed: {
		...mapGetters(['isMentor', 'isKPC']),
		...mapGettersOfClassPtOpen([GET_HAS_REJECTION_NOTICE]),
		...mapGettersOfKdc([GET_IS_KDC_PROGRESS_EXIST]),
		$_isMyPage() {
			return this.$route.path.startsWith(MY_PAGE_PATH);
		},
		$_currentMenu() {
			return this.$_myPageMenus[this.$_currentMenuIndex] || this._myPageSettingMenu;
		},
		$_currentMenuIndex() {
			return this.$_getMenuIndexByPath(this.$route);
		},
		$_hasNavs() {
			return this.$_currentMenu.navs?.length > 0;
		},
		$_myPageMenus() {
			if (this.isMentor) {
				return this.$_mentorMenus;
			}
			return this.$_menteeMenus;
		},
		$_mentorMenus() {
			const mentorMenus = [
				this._myPageClassroomMenu,
				this._myPageClassroomAppliedMenu,
				this._myPageClassroomOpenedMenu,
				this._myPageCourseCreateMenu,
				this._myPageCommunityMenu,
				this._myPageCreditMenu,
				this._myPagePurchaseHistoryMenu,
				this._myPageHistoryMenu,
				this._myPageCSMenu,
			];
			if (this.IS_MOBILE) {
				mentorMenus.splice(mentorMenus.length - 1, 0, this._myPageNoticeMenu);
			}
			return mentorMenus;
		},
		$_menteeMenus() {
			const menteeMenus = [
				this._myPageClassroomMenu,
				this._myPageCommunityMenu,
				// this._myPageMatchingMenu,
				this._myPageSettingMenu,
				this._myPagePurchaseHistoryMenu,
				this._myPageHistoryMenu,
				this._myPageCourseCreateMenu,
				this._myPageCSMenu,
			];
			if (this.IS_MOBILE) {
				menteeMenus.splice(menteeMenus.length - 1, 0, this._myPageNoticeMenu);
			}
			return menteeMenus;
		},
		_myPageHomeMenu() {
			return {
				name: MY_PAGE_MENUS.HOME,
				url: MY_PAGE_PATH,
				isShowBottomBorder: true,
				isTitleDisabled: true,
				isDrawer: false,
			};
		},
		_myPageCommunityMenu() {
			return {
				name: MY_PAGE_MENUS.COMMUNITY,
				url: MY_PAGE_COMMUNITY_PATH,
				navs: this._myPageCommunityMenuNavs,
				isShowBottomBorder: true,
				isDrawer: false,
			};
		},
		_myPageCourseCreateMenu() {
			return {
				name: MY_PAGE_MENUS.CREATE_CLASS,
				url: MY_PAGE_CREATE_CLASS_PATH,
				navs: this._myPageClassOpenMenuNavs,
				isShowBottomBorder: this.isMentor,
				isSubMenu: this.isMentor,
				//  개설시스템에서 반려된 항목이 하나라도 있을 경우
				notice: this[GET_HAS_REJECTION_NOTICE],
				isDrawer: true,
				buttonInfo: {
					hasButton: true,
					buttonText: MY_PAGE_MENUS.CREATE_CLASS,
					iconName: 'IconPlusSmallLine',
					action: MY_PAGE_MENUS.CREATE_CLASS,
				},
			};
		},
		_myPageMenteePassedMenu() {
			return {
				name: MY_PAGE_MENUS.THANKS,
				url: MY_PAGE_THANKS_PATH,
				navs: this._myPageMenteePassedMenuNavs,
				isShowInfoBoard: true,
				isShowBottomBorder: this.isMentor,
				isDrawer: false,
			};
		},
		_myPageCreditMenu() {
			return {
				name: MY_PAGE_MENUS.CREDIT_CASH,
				url: MY_PAGE_CREDIT_CASH_PATH,
				navs: this._myPageCreditMenuNavs,
				isShowInfoBoard: true,
				isDrawer: false,
			};
		},
		_myPageClassroomOpenedMenu() {
			return {
				name: MY_PAGE_MENUS.CLASSROOM_OPENED,
				url: MY_PAGE_CLASSROOM_OPENED_PATH,
				navs: this._myPageClassroomMenuNavs,
				isShowBottomBorder: !this.isMentor,
				isSubMenu: true,
			};
		},
		_myPageClassroomAppliedMenu() {
			return {
				name: MY_PAGE_MENUS.CLASSROOM_APPLIED,
				url: MY_PAGE_CLASSROOM_APPLIED_PATH,
				navs: this._myPageClassroomMenuNavs,
				isShowBottomBorder: !this.isMentor,
				popover:
					this[GET_IS_KDC_PROGRESS_EXIST] && this.isMentor
						? '<span style="color: #CF5310">신청 중인 과정이 있어요!</span>'
						: '',
				isSubMenu: true,
			};
		},
		_myPageClassroomMenu() {
			return {
				name: MY_PAGE_MENUS.CLASSROOM,
				url: this.isMentor ? MY_PAGE_CLASSROOM_PATH : MY_PAGE_CLASSROOM_APPLIED_PATH,
				navs: this._myPageClassroomMenuNavs,
				popover:
					this[GET_IS_KDC_PROGRESS_EXIST] && !this.isMentor
						? '<span style="color: #CF5310">신청 중인 과정이 있어요!</span>'
						: '',
				isDrawer: false,
				hasSubMenu: this.isMentor,
			};
		},
		_myPageMatchingMenu() {
			return {
				name: MY_PAGE_MENUS.MATCHING,
				url: '/mypage/matching',
				isDrawer: false,
			};
		},
		_myPagePurchaseHistoryMenu() {
			return {
				name: MY_PAGE_MENUS.PURCHASE_HISTORY,
				url: MY_PAGE_PURCHASE_HISTORY_PATH,
				navs: this._myPagePurchaseHistoryMenuNavs,
				isDrawer: false,
			};
		},
		_myPageSettingMenu() {
			return {
				name: '내 정보',
				url: MY_PAGE_SETTING_PATH,
				navs: this._myPageSettingMenuNavs,
				isDrawer: false,
			};
		},
		_myPageCSMenu() {
			return {
				name: MY_PAGE_MENUS.CS,
				url: `${baseUrl}${CS_PATH}`,
				nuxt: false,
				target: '_blank',
				isDrawer: true,
			};
		},
		_myPageNoticeMenu() {
			return {
				name: MY_PAGE_MENUS.NOTICE,
				url: NOTICE_PATH,
			};
		},
		_myPageHistoryMenu() {
			const historyMenuUrl = this.isKPC ? MY_PAGE_AI_ANALYTICS_PATH : RESUME_INDEX_PATH;
			return {
				name: MY_PAGE_MENUS.HISTORY,
				url: historyMenuUrl,
				navs: this._myPageHistoryMenuNavs,
				isShowInfoBoard: true,
				isDrawer: false,
				target: this.isKPC ? '_self' : '_blank',
			};
		},
		_myPageCreditMenuNavs() {
			return [
				{
					name: '크레딧샵',
					url: MY_PAGE_CREDIT_SHOP_PATH,
				},
				{
					name: '사용/적립 내역',
					url: MY_PAGE_CREDIT_LIST_PATH,
				},
				{
					name: '현금 환급 내역',
					url: MY_PAGE_CREDIT_CASH_PATH,
				},
			];
		},
		_myPagePurchaseHistoryMenuNavs() {
			return [
				// {
				// 	name: '결제 내역',
				// 	url: MY_PAGE_PURCHASE_HISTORY_PATH,
				// },
				// {
				// 	name: '환불 내역',
				// 	url: `${MY_PAGE_PURCHASE_HISTORY_PATH}${MY_PAGE_NAV_PREFIX}${MY_PAGE_PURCHASE_NAV.REFUND}`,
				// },
			];
		},
		_myPageCommunityMenuNavs() {
			return [
				{
					name: MY_PAGE_ALL_NAME,
					url: MY_PAGE_COMMUNITY_PATH,
				},
				{
					name: '내 글',
					url: `${MY_PAGE_COMMUNITY_PATH}${MY_PAGE_NAV_PREFIX}${MY_PAGE_COMMUNITY_NAV.MY_POST}`,
				},
				{
					name: '내 댓글',
					url: `${MY_PAGE_COMMUNITY_PATH}${MY_PAGE_NAV_PREFIX}${MY_PAGE_COMMUNITY_NAV.MY_COMMENT}`,
				},
				{
					name: 'AI 자기소개서 분석기',
					url: MY_PAGE_AI_ANALYTICS_PATH,
				},
				{
					name: '자기소개서 튜토리얼',
					url: MY_PAGE_ESSAY_PATH,
				},

				// {
				// 	name: '좋아한 글',
				// 	url: `${MY_PAGE_COMMUNITY_PATH}${MY_PAGE_NAV_PREFIX}${MY_PAGE_COMMUNITY_NAV.MY_POST_LIKED}`,
				// },
				// {
				// 	name: '좋아한 댓글',
				// 	url: `${MY_PAGE_COMMUNITY_PATH}${MY_PAGE_NAV_PREFIX}${MY_PAGE_COMMUNITY_NAV.MY_COMMENT_LIKED}`,
				// },
			];
		},
		_myPageClassOpenMenuNavs() {
			return [
				{
					name: MY_PAGE_ALL_NAME,
					url: MY_PAGE_CREATE_CLASS_PATH,
				},
				{
					name: '직무부트캠프',
					url: `${MY_PAGE_CREATE_CLASS_PATH}${MY_PAGE_NAV_PREFIX}${MY_PAGE_CREATE_CLASS_NAV.EDU}`,
				},
				{
					name: '실무PT',
					url: `${MY_PAGE_CREATE_CLASS_PATH}${MY_PAGE_NAV_PREFIX}${MY_PAGE_CREATE_CLASS_NAV.PT}`,
				},
			];
		},
		_myPageMenteePassedMenuNavs() {
			return [
				{
					name: '합격한 멘티',
					url: MY_PAGE_THANKS_PATH,
				},
				{
					name: '내가 받은 감사 인사',
					url: `${MY_PAGE_THANKS_PATH}${MY_PAGE_NAV_PREFIX}message`,
				},
			];
		},
		_myPageClassroomMenuNavs() {
			const isOpenedClassPage = this.$route.name === 'mypage-classroom-opened';
			const myPageClassroomPathForUserType = isOpenedClassPage
				? MY_PAGE_CLASSROOM_OPENED_PATH
				: MY_PAGE_CLASSROOM_APPLIED_PATH;
			const myPageNavs = [
				{
					name: MY_PAGE_ALL_NAME,
					url: myPageClassroomPathForUserType,
				},
				{
					name: '직무부트캠프',
					url: `${myPageClassroomPathForUserType}${MY_PAGE_NAV_PREFIX}${MY_PAGE_CLASSROOM_NAV.EDU}`,
				},
				{
					name: '실무PT',
					url: `${myPageClassroomPathForUserType}${MY_PAGE_NAV_PREFIX}${MY_PAGE_CLASSROOM_NAV.PT}`,
				},
				{
					name: 'VOD',
					url: `${myPageClassroomPathForUserType}${MY_PAGE_NAV_PREFIX}${MY_PAGE_CLASSROOM_NAV.VOD}`,
				},
				{
					name: '진로부트캠프',
					url: `${myPageClassroomPathForUserType}${MY_PAGE_NAV_PREFIX}${MY_PAGE_CLASSROOM_NAV.PATH}`,
				},
				{
					name: '국비지원',
					url: `${myPageClassroomPathForUserType}${MY_PAGE_NAV_PREFIX}${MY_PAGE_CLASSROOM_NAV.KDC}`,
				},
				{
					name: '가상회사',
					url: `${myPageClassroomPathForUserType}${MY_PAGE_NAV_PREFIX}${MY_PAGE_CLASSROOM_NAV.VIRTUAL_COMPANY}`,
				},
			];
			const myPageExternshipNavItem = {
				name: '익스턴십',
				url: `${myPageClassroomPathForUserType}${MY_PAGE_NAV_PREFIX}${MY_PAGE_CLASSROOM_NAV.EXTERNSHIP}`,
			};
			if (this.isMentor && isOpenedClassPage) {
				return myPageNavs;
			}
			return [...myPageNavs, myPageExternshipNavItem];
		},
		_myPageSettingMenuNavs() {
			const settingNavs = this.isMentor
				? [
						{
							name: MY_PAGE_SETTING_NAME,
							url: MY_PAGE_SETTING_PATH,
						},
						{
							name: MY_PAGE_SETTING_CAREER_NAME,
							url: `${MY_PAGE_SETTING_CAREER_PC_PATH}`,
						},
						{
							name: MY_PAGE_SETTING_NOTI_NAME,
							url: `${MY_PAGE_SETTING_NOTI_PC_PATH}`,
						},
						{
							name: MY_PAGE_SETTING_QUIT_NAME,
							url: `${MY_PAGE_SETTING_QUIT_PATH}`,
						},
				  ]
				: [
						{
							name: MY_PAGE_SETTING_NAME,
							url: MY_PAGE_SETTING_PATH,
						},
						{
							name: MY_PAGE_SETTING_QUIT_NAME,
							url: `${MY_PAGE_SETTING_QUIT_PATH}`,
						},
				  ];
			return this.IS_MOBILE ? [] : settingNavs;
		},
		_myPageHistoryMenuNavs() {
			if (this.isKPC) {
				// KPC는 이력서 관리를 새창으로 띄우기 때문에 메뉴 순서가 2번째임
				return [
					{
						name: 'AI 분석기 이력',
						url: MY_PAGE_AI_ANALYTICS_PATH,
					},
					{
						name: '이력서 관리',
						url: `${baseUrl}${RESUME_INDEX_PATH}`,
						nuxt: false,
						target: '_blank',
					},
				];
			}

			return [
				// {
				// 	name: '자기소개서 관리',
				// 	url: MY_PAGE_ESSAY_PATH,
				// },
				{
					name: '이력서 관리',
					url: `${baseUrl}${RESUME_INDEX_PATH}`,
					nuxt: false,
					target: '_blank',
				},
				// {
				// 	name: 'AI 분석기 이력',
				// 	url: MY_PAGE_AI_ANALYTICS_PATH,
				// },
			];
		},
	},
	watch: {
		$route() {
			// 뒤로가기등으로 페이지가 변경 되었을때 대응하기 위함
			this.$_handleNotScroll(false);
		},
	},
	methods: {
		$_getMenuIndexByPath({ path }) {
			let index;
			index = this.$_myPageMenus.findIndex(({ url }) => {
				if (path === MY_PAGE_PATH || url === MY_PAGE_PATH) {
					return false;
				}
				return path === url ?? path.startsWith(url);
			});
			if (index !== -1) {
				return index;
			}
			// index를 찾지 못했다면 nav의 url에서 찾는다.
			index = this.$_myPageMenus.findIndex(menu => menu.navs?.some(nav => path.startsWith(nav.url)));
			if (index !== -1) {
				return index;
			}
			return index;
		},
		$_isCurrentPath(path) {
			const { fullPath } = this.$route;
			return fullPath.includes(path);
		},
		$_showErrorToast() {
			Toast.show({
				message: '오류가 발생했습니다. 고객센터로 문의해주세요.',
				type: 'error',
				position: 'top',
				timeout: 3000,
				showIcon: false,
			});
		},
		$_showSubmitSuccessToast() {
			Toast.show({
				message: '저장되었습니다',
				type: 'success',
				position: 'top',
				timeout: 3000,
				showIcon: true,
			});
		},
	},
};

export default myPageMixin;
