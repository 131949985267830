import { API_EDU_B2B_PREFIX, API_EDU_PREFIX } from '@/plugins/constants';

export default axios => ({
	/**
	 * 클래스룸 캠프 정보 불러오기
	 * @apiParam {Number} campNo 캠프 번호
	 */
	fetchClassroomCampInfo(params) {
		return axios.get(`${API_EDU_PREFIX}classroom/camp`, { params });
	},

	/**
	 * 보안서약서 서명 여부 확인
	 * @apiParam {Number} campNo 캠프 번호
	 */
	async fetchNdaConfirmed(camp_no) {
		try {
			const params = { camp_no };
			const {
				data: { isVerified, secret_url },
			} = await axios.get(`${API_EDU_PREFIX}nda/verify`, { params });

			return { isVerified, secret_url };
		} catch (error) {
			console.error(error);
			return { isVerified: false, secret_url: false };
		}
	},

	/**
	 * 멘티 가이드 확인 여부 체크
	 * @param {number} campNo 캠프 번호
	 * @param {string} type guide | ot
	 * @param {string} product free_schedule | eduPath
	 */
	async fetchUserReadGuide(campNo, type, product) {
		try {
			const ACTION_NAME = `user_camp_${type}_${product}|${campNo}`;
			const params = { action: ACTION_NAME };
			const { data } = await axios.get('/api/user/action', { params });
			return data;
		} catch (error) {
			console.error(error);
			return null;
		}
	},

	/**
	 * 멘티 가이드 확인 여부 등록
	 * @param {number} campNo 캠프 번호
	 * @param {string} type guide | ot
	 * @param {string} product free_schedule | eduPath
	 */
	async addUserReadGuideAction(campNo, type, product) {
		try {
			const ACTION_NAME = `user_camp_${type}_${product}|${campNo}`;
			const data = { action: ACTION_NAME };
			await axios.post('/api/user/action', data);
		} catch (error) {
			console.error(error);
			throw error;
		}
	},

	/**
	 * 과제 제출 정보 가져오기
	 * @apiParam {Number} campNo 캠프 번호
	 */
	async getAssignmentCatalog(camp_no) {
		try {
			const params = { camp_no };
			const { data } = await axios.get(`${API_EDU_PREFIX}classroom/assignment/catalog`, { params });
			return data;
		} catch (error) {
			console.error(error);
			return null;
		}
	},

	/**
	 * B2B 캠프 유형 판단 정보 가져오기
	 * @apiParam {Number} applicant_no 유저 번호
	 */
	async getB2BApplicantInfo(applicant_no) {
		try {
			const params = { applicant_no };
			const { data } = await axios.post(`${API_EDU_B2B_PREFIX}applicant-info`, params);
			return data;
		} catch (error) {
			console.error(error);
		}
	},

	async getB2BCampSubmitted(applicant_no, survey_url) {
		try {
			const params = {
				applicant_no,
				survey_url,
				type: 'edu_camp',
			};
			const {
				data: { isSubmitted },
			} = await axios.post(`/api/survey/check-submit`, params);
			return isSubmitted;
		} catch (error) {
			console.error(error);
		}
	},
	checkFeedbackDownload(params) {
		return axios.get(`/api/edu/classroom/checkFeedbackDownload`, { params });
	},
	getAssignmentQuestion() {
		return axios.get(`/api/edu/classroom/assignment/question`);
	},
	/**
	 * 자율일정 과제 가져오기
	 * @params group_id
	 */
	getAdditionalList(params) {
		return axios.get(`/api/edu/classroom/additional`, { params });
	},
	/**
	 * 클래스룸 진입시 3개의 퀘스트를 전부 했는지 판단하는 API
	 * @param params.userNo
	 * @param params.campNo
	 * @param params.applicantNo
	 * @returns {*}
	 */
	checkQuestInformation(params) {
		return axios.post(`/api/edu/classroom/quest/information`, params);
	},
	/**
	 * Modal 띄웠을때 각각 퀘스트를 했는지 판단하는 API
	 * @param params.userNo
	 * @param params.campNo
	 * @param params.applicantNo
	 * @param params.step: 1~7
	 * @returns {check: boolean}
	 */
	checkEduWorkExperience(params) {
		return axios.get(`/api/edu/work-experience/check3`, { params });
	},
	/**
	 * 다짐 남기기
	 * @param params
	 * @param params.userNo
	 * @param params.campNo
	 * @param params.applicantNo
	 * @param params.expect
	 * @param params.message: 다짐
	 * @returns {*}
	 */
	submitWorkExperienceMessage(params) {
		return axios.post(`/api/edu/work-experience/submit3`, params);
	},
	/**
	 * 편지 퀘스트 완료 API
	 * @param params.userNo
	 * @param params.campNo
	 * @param params.applicantNo
	 * @param params.questName: 퀘스트 이름(letter 등)
	 * @returns {*}
	 */
	completeClassroomEduQuest(params) {
		return axios.post(`/api/edu/classroom/quest/complete`, params);
	},
	/**
	 * 클래스룸 이벤트 스트림 가져오기
	 * @param {number} params.campNo
	 */
	getClassroomEventStream(params) {
		return axios.get(`${API_EDU_PREFIX}classroom/feed/timeline`, { params });
	},
	/**
	 * 클래스룸 대시보드 상태 가져오기
	 * @param {number} params.campNo
	 */
	getClassroomDashboardProgress(params) {
		return axios.get(`${API_EDU_PREFIX}classroom/dashboard/progress/state`, { params });
	},
	/**
	 * 리드멘토 기업 특정 방지 인지 여부
	 * @param {number} params.group_no
	 */
	checkMentorPrivacyModalShouldAppear(params) {
		return axios.get(`${API_EDU_PREFIX}classroom/mentor-privacy`, { params });
	},
	/**
	 * 리드멘토 기업 특정 방지 인지 제출
	 * @param {number} data.group_no
	 * @param {string} data.content
	 */
	submitMentorPrivacyConfirmRequest(data) {
		return axios.post(`${API_EDU_PREFIX}classroom/mentor-privacy`, data);
	},
	/**
	 * 중간보고 list 가져오기
	 * @param {edu_camp_no} params.edu_camp_no
	 */
	getClassroomWeeklyReportList(params) {
		return axios.get(`${API_EDU_PREFIX}classroom/report/list`, { params });
	},
	/**
	 * 중간보고 detail 가져오기
	 * @param {number} params.week
	 */
	getClassroomWeeklyReportDetail(params) {
		return axios.get(`${API_EDU_PREFIX}classroom/report/detail`, { params });
	},
	/**
	 * 중간보고 저장
	 * @param {number} params.week
	 * @param {number} params.edu_camp_no
	 * @param {string} params.report
	 */
	saveClassroomWeeklyReport(params) {
		return axios.post(`${API_EDU_PREFIX}classroom/report/store`, params);
	},
	/**
	 * 중간보고 수정
	 * @param {number} params.id
	 * @param {number} params.week
	 * @param {number} params.edu_camp_no
	 * @param {string} params.report
	 */
	updateClassroomWeeklyReport(params) {
		return axios.post(`${API_EDU_PREFIX}classroom/report/update`, params);
	},
	/**
	 * 업무요청 및 자료 파일 확인하기
	 * @param {number} params.week
	 * @param {number} params.camp_no
	 */
	getClassroomInstruction(params) {
		return axios.get(`${API_EDU_PREFIX}classroom/instruction`, { params });
	},
	/**
	 * 업무요청서 저장
	 * @param {number} params.week
	 * @param {number} params.campNo
	 */
	postEduInstructionRequest(params) {
		return axios.post(`${API_EDU_PREFIX}classroom/instruction/request`, params);
	},
	/**
	 * 업무요청서 가져오기
	 * @param {number} params.week
	 * @param {number} params.campNo
	 */
	getEduInstructionRequest(params) {
		return axios.get(`${API_EDU_PREFIX}classroom/instruction/request`, { params });
	},
});
