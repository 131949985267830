import gtmMixin from '@/mixins/gtmMixin';

const answerGtmMixin = {
	mixins: [gtmMixin],
	methods: {
		/**
		 * 답변하기 리스트에서 크레딧 정보 클릭
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param user_level
		 * @param revenue_type
		 */
		$_clickCreditBox({ user_no, user_type, email, user_level, revenue_type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				user_level,
				revenue_type,
				event: 'clickCreditBox',
			});
		},
	},
};

export default answerGtmMixin;
