import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ff2114aa = () => interopDefault(import('../pages/answer/index.vue' /* webpackChunkName: "pages/answer/index" */))
const _11751db6 = () => interopDefault(import('../pages/app-page.vue' /* webpackChunkName: "pages/app-page" */))
const _2be1f458 = () => interopDefault(import('../pages/app-page/_component.vue' /* webpackChunkName: "pages/app-page/_component" */))
const _eac2ceba = () => interopDefault(import('../pages/bookmark/index.vue' /* webpackChunkName: "pages/bookmark/index" */))
const _6a99e401 = () => interopDefault(import('../pages/class/index.vue' /* webpackChunkName: "pages/class/index" */))
const _3e4520ae = () => interopDefault(import('../pages/comento/index.vue' /* webpackChunkName: "pages/comento/index" */))
const _6b5786bc = () => interopDefault(import('../pages/edu.vue' /* webpackChunkName: "pages/edu" */))
const _4c7fdf24 = () => interopDefault(import('../pages/edu/alumni/index.vue' /* webpackChunkName: "pages/edu/alumni/index" */))
const _70fc5984 = () => interopDefault(import('../pages/edu/list/index.vue' /* webpackChunkName: "pages/edu/list/index" */))
const _6b8ecd16 = () => interopDefault(import('../pages/edu/work-experience/index.vue' /* webpackChunkName: "pages/edu/work-experience/index" */))
const _09c65d06 = () => interopDefault(import('../pages/edu/camp/encore/index.vue' /* webpackChunkName: "pages/edu/camp/encore/index" */))
const _71ee69f8 = () => interopDefault(import('../pages/edu/mentor/thanks2021/index.vue' /* webpackChunkName: "pages/edu/mentor/thanks2021/index" */))
const _4e0d00f8 = () => interopDefault(import('../pages/edu/path/list/index.vue' /* webpackChunkName: "pages/edu/path/list/index" */))
const _19e9ee18 = () => interopDefault(import('../pages/edu/path/survey/index.vue' /* webpackChunkName: "pages/edu/path/survey/index" */))
const _586272fc = () => interopDefault(import('../pages/edu/security/nda/index.vue' /* webpackChunkName: "pages/edu/security/nda/index" */))
const _32a04b86 = () => interopDefault(import('../pages/edu/survey/purchase/index.vue' /* webpackChunkName: "pages/edu/survey/purchase/index" */))
const _0566f101 = () => interopDefault(import('../pages/edu/mentor/kakao/_token.vue' /* webpackChunkName: "pages/edu/mentor/kakao/_token" */))
const _5108296f = () => interopDefault(import('../pages/edu/work-experience/detail/_campNo.vue' /* webpackChunkName: "pages/edu/work-experience/detail/_campNo" */))
const _2c8c6c48 = () => interopDefault(import('../pages/edu/live-class/_meetingId.vue' /* webpackChunkName: "pages/edu/live-class/_meetingId" */))
const _52b65eec = () => interopDefault(import('../pages/edu/schedule/_campNo.vue' /* webpackChunkName: "pages/edu/schedule/_campNo" */))
const _d6b335fc = () => interopDefault(import('../pages/edu/attendance/_campNo/_week.vue' /* webpackChunkName: "pages/edu/attendance/_campNo/_week" */))
const _69e0087a = () => interopDefault(import('../pages/edu/hr-report/_campNo/_week.vue' /* webpackChunkName: "pages/edu/hr-report/_campNo/_week" */))
const _252f95f5 = () => interopDefault(import('../pages/edu/learn/_top/_camp.vue' /* webpackChunkName: "pages/edu/learn/_top/_camp" */))
const _9b513db6 = () => interopDefault(import('../pages/mypage.vue' /* webpackChunkName: "pages/mypage" */))
const _4a9db7e8 = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _378cb9c2 = () => interopDefault(import('../pages/mypage/analytics/index.vue' /* webpackChunkName: "pages/mypage/analytics/index" */))
const _13b464c8 = () => interopDefault(import('../pages/mypage/class-open/index.vue' /* webpackChunkName: "pages/mypage/class-open/index" */))
const _8edd4ca8 = () => interopDefault(import('../pages/mypage/classroom/index.vue' /* webpackChunkName: "pages/mypage/classroom/index" */))
const _3b3057c2 = () => interopDefault(import('../pages/mypage/community/index.vue' /* webpackChunkName: "pages/mypage/community/index" */))
const _84e3595a = () => interopDefault(import('../pages/mypage/credit.vue' /* webpackChunkName: "pages/mypage/credit" */))
const _7af9ece2 = () => interopDefault(import('../pages/mypage/credit/cash/index.vue' /* webpackChunkName: "pages/mypage/credit/cash/index" */))
const _4ad2cee6 = () => interopDefault(import('../pages/mypage/credit/list/index.vue' /* webpackChunkName: "pages/mypage/credit/list/index" */))
const _2b5d7656 = () => interopDefault(import('../pages/mypage/essay/index.vue' /* webpackChunkName: "pages/mypage/essay/index" */))
const _68c5011f = () => interopDefault(import('../pages/mypage/payment/index.vue' /* webpackChunkName: "pages/mypage/payment/index" */))
const _774088e9 = () => interopDefault(import('../pages/mypage/setting/index.vue' /* webpackChunkName: "pages/mypage/setting/index" */))
const _bed091ba = () => interopDefault(import('../pages/mypage/thanks.vue' /* webpackChunkName: "pages/mypage/thanks" */))
const _76f91766 = () => interopDefault(import('../pages/mypage/thanks/index.vue' /* webpackChunkName: "pages/mypage/thanks/index" */))
const _5b72349a = () => interopDefault(import('../pages/mypage/classroom/applied/index.vue' /* webpackChunkName: "pages/mypage/classroom/applied/index" */))
const _2e2d7ac2 = () => interopDefault(import('../pages/mypage/classroom/opened/index.vue' /* webpackChunkName: "pages/mypage/classroom/opened/index" */))
const _6b7c907c = () => interopDefault(import('../pages/mypage/profile/mentoring-reviews/index.vue' /* webpackChunkName: "pages/mypage/profile/mentoring-reviews/index" */))
const _5009748c = () => interopDefault(import('../pages/mypage/setting/career/index.vue' /* webpackChunkName: "pages/mypage/setting/career/index" */))
const _c1b5271c = () => interopDefault(import('../pages/mypage/setting/noti/index.vue' /* webpackChunkName: "pages/mypage/setting/noti/index" */))
const _25faf8ea = () => interopDefault(import('../pages/mypage/setting/quit/index.vue' /* webpackChunkName: "pages/mypage/setting/quit/index" */))
const _94025320 = () => interopDefault(import('../pages/mypage/shop/list/index.vue' /* webpackChunkName: "pages/mypage/shop/list/index" */))
const _f3339776 = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _78129b11 = () => interopDefault(import('../pages/test-editor/index.vue' /* webpackChunkName: "pages/test-editor/index" */))
const _0620bb14 = () => interopDefault(import('../pages/vod/index.vue' /* webpackChunkName: "pages/vod/index" */))
const _5aa7cabd = () => interopDefault(import('../pages/bookmark/community/index.vue' /* webpackChunkName: "pages/bookmark/community/index" */))
const _758e8e0c = () => interopDefault(import('../pages/career/dreamverse/index.vue' /* webpackChunkName: "pages/career/dreamverse/index" */))
const _9d732f54 = () => interopDefault(import('../pages/career/externship/index.vue' /* webpackChunkName: "pages/career/externship/index" */))
const _17aed2da = () => interopDefault(import('../pages/career/recruit/index.vue' /* webpackChunkName: "pages/career/recruit/index" */))
const _8f52f9b0 = () => interopDefault(import('../pages/class/pt/index.vue' /* webpackChunkName: "pages/class/pt/index" */))
const _51fd3fbe = () => interopDefault(import('../pages/class/training/index.vue' /* webpackChunkName: "pages/class/training/index" */))
const _fc5e997e = () => interopDefault(import('../pages/classroom/submit/index.vue' /* webpackChunkName: "pages/classroom/submit/index" */))
const _3c5da566 = () => interopDefault(import('../pages/classroom/weekly-report/index.vue' /* webpackChunkName: "pages/classroom/weekly-report/index" */))
const _16c60288 = () => interopDefault(import('../pages/classroom/work-request/index.vue' /* webpackChunkName: "pages/classroom/work-request/index" */))
const _31c0f70a = () => interopDefault(import('../pages/community/insight/index.vue' /* webpackChunkName: "pages/community/insight/index" */))
const _6c5f7b0d = () => interopDefault(import('../pages/community/lounge/index.vue' /* webpackChunkName: "pages/community/lounge/index" */))
const _457c5139 = () => interopDefault(import('../pages/events/comento_skt/index.vue' /* webpackChunkName: "pages/events/comento_skt/index" */))
const _d3a142c0 = () => interopDefault(import('../pages/events/data_quiz-2022/index.vue' /* webpackChunkName: "pages/events/data_quiz-2022/index" */))
const _2dd8be80 = () => interopDefault(import('../pages/events/hr_convert_con/index.vue' /* webpackChunkName: "pages/events/hr_convert_con/index" */))
const _66c7fb1c = () => interopDefault(import('../pages/events/referral/index.vue' /* webpackChunkName: "pages/events/referral/index" */))
const _6854e9ba = () => interopDefault(import('../pages/events/thanks-2023/index.vue' /* webpackChunkName: "pages/events/thanks-2023/index" */))
const _67aca248 = () => interopDefault(import('../pages/notice/create/index.vue' /* webpackChunkName: "pages/notice/create/index" */))
const _13bc0f0a = () => interopDefault(import('../pages/open-chat/link/index.vue' /* webpackChunkName: "pages/open-chat/link/index" */))
const _5cc2ec95 = () => interopDefault(import('../pages/product/b2b-gen-ai-class.vue' /* webpackChunkName: "pages/product/b2b-gen-ai-class" */))
const _2f7b0e44 = () => interopDefault(import('../pages/product/class.vue' /* webpackChunkName: "pages/product/class" */))
const _59083334 = () => interopDefault(import('../pages/product/externship.vue' /* webpackChunkName: "pages/product/externship" */))
const _32837f61 = () => interopDefault(import('../pages/survey/community/index.vue' /* webpackChunkName: "pages/survey/community/index" */))
const _824ad6de = () => interopDefault(import('../pages/test-editor/tinymce/index.vue' /* webpackChunkName: "pages/test-editor/tinymce/index" */))
const _2e0950d2 = () => interopDefault(import('../pages/vod/player/index.vue' /* webpackChunkName: "pages/vod/player/index" */))
const _c9fc693a = () => interopDefault(import('../pages/class/on-air/list/index.vue' /* webpackChunkName: "pages/class/on-air/list/index" */))
const _0fac9ee7 = () => interopDefault(import('../pages/class/pt/open/index.vue' /* webpackChunkName: "pages/class/pt/open/index" */))
const _32de2b33 = () => interopDefault(import('../pages/class/sesac/gangseo_dt2/index.vue' /* webpackChunkName: "pages/class/sesac/gangseo_dt2/index" */))
const _707a4418 = () => interopDefault(import('../pages/class/sesac/gangseo_dt3/index.vue' /* webpackChunkName: "pages/class/sesac/gangseo_dt3/index" */))
const _004819a2 = () => interopDefault(import('../pages/class/sesac/seongdong_dt1/index.vue' /* webpackChunkName: "pages/class/sesac/seongdong_dt1/index" */))
const _753af87b = () => interopDefault(import('../pages/class/sesac/yongsan_dt3/index.vue' /* webpackChunkName: "pages/class/sesac/yongsan_dt3/index" */))
const _4a247403 = () => interopDefault(import('../pages/class/vod/list.vue' /* webpackChunkName: "pages/class/vod/list" */))
const _58b2c48f = () => interopDefault(import('../pages/classroom/path/submit/index.vue' /* webpackChunkName: "pages/classroom/path/submit/index" */))
const _05a52e20 = () => interopDefault(import('../pages/classroom/weekly-report/submit/index.vue' /* webpackChunkName: "pages/classroom/weekly-report/submit/index" */))
const _20d5d5e6 = () => interopDefault(import('../pages/community/insight/create/index.vue' /* webpackChunkName: "pages/community/insight/create/index" */))
const _b37e91d8 = () => interopDefault(import('../pages/community/lounge/create/index.vue' /* webpackChunkName: "pages/community/lounge/create/index" */))
const _ebbe9a28 = () => interopDefault(import('../pages/kdc/apply/complete/index.vue' /* webpackChunkName: "pages/kdc/apply/complete/index" */))
const _38b8b020 = () => interopDefault(import('../pages/refund/camp/apply/index.vue' /* webpackChunkName: "pages/refund/camp/apply/index" */))
const _ea8e377c = () => interopDefault(import('../pages/refund/camp/holdon/index.vue' /* webpackChunkName: "pages/refund/camp/holdon/index" */))
const _afa72e02 = () => interopDefault(import('../pages/refund/camp/list.vue' /* webpackChunkName: "pages/refund/camp/list" */))
const _4bdb00b6 = () => interopDefault(import('../pages/vod/player/checkplus/index.vue' /* webpackChunkName: "pages/vod/player/checkplus/index" */))
const _66677282 = () => interopDefault(import('../pages/class/pt/dashboard/woowa/index.vue' /* webpackChunkName: "pages/class/pt/dashboard/woowa/index" */))
const _56c1424c = () => interopDefault(import('../pages/class/pt/pay/complete.vue' /* webpackChunkName: "pages/class/pt/pay/complete" */))
const _06704064 = () => interopDefault(import('../pages/class/vod/detail/sample/_no.vue' /* webpackChunkName: "pages/class/vod/detail/sample/_no" */))
const _4da93e9d = () => interopDefault(import('../pages/preview/class/on-air/detail/_no.vue' /* webpackChunkName: "pages/preview/class/on-air/detail/_no" */))
const _58244a64 = () => interopDefault(import('../pages/class/on-air/detail/_no.vue' /* webpackChunkName: "pages/class/on-air/detail/_no" */))
const _c3f6725e = () => interopDefault(import('../pages/class/pt/list/_keyword.vue' /* webpackChunkName: "pages/class/pt/list/_keyword" */))
const _3cc1a972 = () => interopDefault(import('../pages/class/vod/detail/_no.vue' /* webpackChunkName: "pages/class/vod/detail/_no" */))
const _3b35dfdc = () => interopDefault(import('../pages/classroom/path/assignment/_no.vue' /* webpackChunkName: "pages/classroom/path/assignment/_no" */))
const _bc5d2f5a = () => interopDefault(import('../pages/classroom/pt/assignment/_no.vue' /* webpackChunkName: "pages/classroom/pt/assignment/_no" */))
const _440eecb8 = () => interopDefault(import('../pages/vod/player/checkplus/_status.vue' /* webpackChunkName: "pages/vod/player/checkplus/_status" */))
const _29de5d50 = () => interopDefault(import('../pages/class/pt/content/_title/_classNo.vue' /* webpackChunkName: "pages/class/pt/content/_title/_classNo" */))
const _04fb9037 = () => interopDefault(import('../pages/class/pt/contents/_top/_class.vue' /* webpackChunkName: "pages/class/pt/contents/_top/_class" */))
const _6ef877e1 = () => interopDefault(import('../pages/vod/player/lecture/_lectureId/_lessonId/index.vue' /* webpackChunkName: "pages/vod/player/lecture/_lectureId/_lessonId/index" */))
const _2fbaa41f = () => interopDefault(import('../pages/affiliate/edu-coupon/_encrypt/index.vue' /* webpackChunkName: "pages/affiliate/edu-coupon/_encrypt/index" */))
const _4eec67ba = () => interopDefault(import('../pages/career/dreamverse/_partnerWithId/index.vue' /* webpackChunkName: "pages/career/dreamverse/_partnerWithId/index" */))
const _32967eb3 = () => interopDefault(import('../pages/career/recruit/_recruitIdWithCompanyName/index.vue' /* webpackChunkName: "pages/career/recruit/_recruitIdWithCompanyName/index" */))
const _43a7e9fc = () => interopDefault(import('../pages/class/sesac/_partnerWithRound.vue' /* webpackChunkName: "pages/class/sesac/_partnerWithRound" */))
const _3bc21a44 = () => interopDefault(import('../pages/classroom/assignment/_no.vue' /* webpackChunkName: "pages/classroom/assignment/_no" */))
const _1f8b239f = () => interopDefault(import('../pages/classroom/path/_no/index.vue' /* webpackChunkName: "pages/classroom/path/_no/index" */))
const _92323284 = () => interopDefault(import('../pages/classroom/pt/_no/index.vue' /* webpackChunkName: "pages/classroom/pt/_no/index" */))
const _0a467900 = () => interopDefault(import('../pages/community/insight/_title.vue' /* webpackChunkName: "pages/community/insight/_title" */))
const _1d9b9f2e = () => interopDefault(import('../pages/community/lounge/_title.vue' /* webpackChunkName: "pages/community/lounge/_title" */))
const _6d59c41a = () => interopDefault(import('../pages/forms/typeform/_id.vue' /* webpackChunkName: "pages/forms/typeform/_id" */))
const _8680adac = () => interopDefault(import('../pages/kdc/apply/_lectureId.vue' /* webpackChunkName: "pages/kdc/apply/_lectureId" */))
const _5e6493c0 = () => interopDefault(import('../pages/kdc/progress/_lectureId/index.vue' /* webpackChunkName: "pages/kdc/progress/_lectureId/index" */))
const _6f2c99e4 = () => interopDefault(import('../pages/pt/live-class/_meetingId.vue' /* webpackChunkName: "pages/pt/live-class/_meetingId" */))
const _c8b437f6 = () => interopDefault(import('../pages/affiliate/edu-coupon/_encrypt/abandon/index.vue' /* webpackChunkName: "pages/affiliate/edu-coupon/_encrypt/abandon/index" */))
const _434d6a0c = () => interopDefault(import('../pages/affiliate/edu-coupon/_encrypt/loading/index.vue' /* webpackChunkName: "pages/affiliate/edu-coupon/_encrypt/loading/index" */))
const _6b6b4342 = () => interopDefault(import('../pages/classroom/pt/_no/submit/index.vue' /* webpackChunkName: "pages/classroom/pt/_no/submit/index" */))
const _5ccc3204 = () => interopDefault(import('../pages/kdc/progress/_lectureId/registration/index.vue' /* webpackChunkName: "pages/kdc/progress/_lectureId/registration/index" */))
const _13159e34 = () => interopDefault(import('../pages/classroom/path/_no/detail/_campno.vue' /* webpackChunkName: "pages/classroom/path/_no/detail/_campno" */))
const _e0649b74 = () => interopDefault(import('../pages/kdc/progress/_lectureId/guide/_step.vue' /* webpackChunkName: "pages/kdc/progress/_lectureId/guide/_step" */))
const _2fc26a13 = () => interopDefault(import('../pages/pt/attendance/_ptNo/_week.vue' /* webpackChunkName: "pages/pt/attendance/_ptNo/_week" */))
const _0bfedf23 = () => interopDefault(import('../pages/vod/lecture/_top/_title.vue' /* webpackChunkName: "pages/vod/lecture/_top/_title" */))
const _2ff5032a = () => interopDefault(import('../pages/classroom/_no.vue' /* webpackChunkName: "pages/classroom/_no" */))
const _3cba5da6 = () => interopDefault(import('../pages/job-questions/_keyword.vue' /* webpackChunkName: "pages/job-questions/_keyword" */))
const _446c0cf6 = () => interopDefault(import('../pages/notice/_title.vue' /* webpackChunkName: "pages/notice/_title" */))
const _ade9f862 = () => interopDefault(import('../pages/secrecy/_product.vue' /* webpackChunkName: "pages/secrecy/_product" */))
const _8274799c = () => interopDefault(import('../pages/web-form/_id.vue' /* webpackChunkName: "pages/web-form/_id" */))
const _0b4f5677 = () => interopDefault(import('../pages/pay/_domain/deposit/_partner.vue' /* webpackChunkName: "pages/pay/_domain/deposit/_partner" */))
const _0d42426c = () => interopDefault(import('../pages/search/_product/_keyword.vue' /* webpackChunkName: "pages/search/_product/_keyword" */))
const _f6d6ae4c = () => interopDefault(import('../pages/shop/_product/_id.vue' /* webpackChunkName: "pages/shop/_product/_id" */))
const _0f122fb2 = () => interopDefault(import('../pages/payment/_partner/_type/complete.vue' /* webpackChunkName: "pages/payment/_partner/_type/complete" */))
const _5212e78e = () => interopDefault(import('../pages/apply/_domain/_subDomain/_productId.vue' /* webpackChunkName: "pages/apply/_domain/_subDomain/_productId" */))
const _85a7ec02 = () => interopDefault(import('../pages/job-questions/_company/_position/_questionAndNo.vue' /* webpackChunkName: "pages/job-questions/_company/_position/_questionAndNo" */))
const _36736c70 = () => interopDefault(import('../pages/pay/_domain/_subDomain/_productId.vue' /* webpackChunkName: "pages/pay/_domain/_subDomain/_productId" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/answer",
    component: _ff2114aa,
    name: "answer"
  }, {
    path: "/app-page",
    component: _11751db6,
    name: "app-page",
    children: [{
      path: ":component?",
      component: _2be1f458,
      name: "app-page-component"
    }]
  }, {
    path: "/bookmark",
    component: _eac2ceba,
    name: "bookmark"
  }, {
    path: "/class",
    component: _6a99e401,
    name: "class"
  }, {
    path: "/comento",
    component: _3e4520ae,
    name: "comento"
  }, {
    path: "/edu",
    component: _6b5786bc,
    name: "edu",
    children: [{
      path: "alumni",
      component: _4c7fdf24,
      name: "edu-alumni"
    }, {
      path: "list",
      component: _70fc5984,
      name: "edu-list"
    }, {
      path: "work-experience",
      component: _6b8ecd16,
      name: "edu-work-experience"
    }, {
      path: "camp/encore",
      component: _09c65d06,
      name: "edu-camp-encore"
    }, {
      path: "mentor/thanks2021",
      component: _71ee69f8,
      name: "edu-mentor-thanks2021"
    }, {
      path: "path/list",
      component: _4e0d00f8,
      name: "edu-path-list"
    }, {
      path: "path/survey",
      component: _19e9ee18,
      name: "edu-path-survey"
    }, {
      path: "security/nda",
      component: _586272fc,
      name: "edu-security-nda"
    }, {
      path: "survey/purchase",
      component: _32a04b86,
      name: "edu-survey-purchase"
    }, {
      path: "mentor/kakao/:token?",
      component: _0566f101,
      name: "edu-mentor-kakao-token"
    }, {
      path: "work-experience/detail/:campNo",
      component: _5108296f,
      name: "edu-work-experience-detail-campNo"
    }, {
      path: "live-class/:meetingId?",
      component: _2c8c6c48,
      name: "edu-live-class-meetingId"
    }, {
      path: "schedule/:campNo?",
      component: _52b65eec,
      name: "edu-schedule-campNo"
    }, {
      path: "attendance/:campNo?/:week?",
      component: _d6b335fc,
      name: "edu-attendance-campNo-week"
    }, {
      path: "hr-report/:campNo?/:week?",
      component: _69e0087a,
      name: "edu-hr-report-campNo-week"
    }, {
      path: "learn/:top?/:camp?",
      component: _252f95f5,
      name: "edu-learn-top-camp"
    }]
  }, {
    path: "/mypage",
    component: _9b513db6,
    children: [{
      path: "",
      component: _4a9db7e8,
      name: "mypage"
    }, {
      path: "analytics",
      component: _378cb9c2,
      name: "mypage-analytics"
    }, {
      path: "class-open",
      component: _13b464c8,
      name: "mypage-class-open"
    }, {
      path: "classroom",
      component: _8edd4ca8,
      name: "mypage-classroom"
    }, {
      path: "community",
      component: _3b3057c2,
      name: "mypage-community"
    }, {
      path: "credit",
      component: _84e3595a,
      name: "mypage-credit",
      children: [{
        path: "cash",
        component: _7af9ece2,
        name: "mypage-credit-cash"
      }, {
        path: "list",
        component: _4ad2cee6,
        name: "mypage-credit-list"
      }]
    }, {
      path: "essay",
      component: _2b5d7656,
      name: "mypage-essay"
    }, {
      path: "payment",
      component: _68c5011f,
      name: "mypage-payment"
    }, {
      path: "setting",
      component: _774088e9,
      name: "mypage-setting"
    }, {
      path: "thanks",
      component: _bed091ba,
      children: [{
        path: "",
        component: _76f91766,
        name: "mypage-thanks"
      }]
    }, {
      path: "classroom/applied",
      component: _5b72349a,
      name: "mypage-classroom-applied"
    }, {
      path: "classroom/opened",
      component: _2e2d7ac2,
      name: "mypage-classroom-opened"
    }, {
      path: "profile/mentoring-reviews",
      component: _6b7c907c,
      name: "mypage-profile-mentoring-reviews"
    }, {
      path: "setting/career",
      component: _5009748c,
      name: "mypage-setting-career"
    }, {
      path: "setting/noti",
      component: _c1b5271c,
      name: "mypage-setting-noti"
    }, {
      path: "setting/quit",
      component: _25faf8ea,
      name: "mypage-setting-quit"
    }, {
      path: "shop/list",
      component: _94025320,
      name: "mypage-shop-list"
    }]
  }, {
    path: "/notice",
    component: _f3339776,
    name: "notice"
  }, {
    path: "/test-editor",
    component: _78129b11,
    name: "test-editor"
  }, {
    path: "/vod",
    component: _0620bb14,
    name: "vod"
  }, {
    path: "/bookmark/community",
    component: _5aa7cabd,
    name: "bookmark-community"
  }, {
    path: "/career/dreamverse",
    component: _758e8e0c,
    name: "career-dreamverse"
  }, {
    path: "/career/externship",
    component: _9d732f54,
    name: "career-externship"
  }, {
    path: "/career/recruit",
    component: _17aed2da,
    name: "career-recruit"
  }, {
    path: "/class/pt",
    component: _8f52f9b0,
    name: "class-pt"
  }, {
    path: "/class/training",
    component: _51fd3fbe,
    name: "class-training"
  }, {
    path: "/classroom/submit",
    component: _fc5e997e,
    name: "classroom-submit"
  }, {
    path: "/classroom/weekly-report",
    component: _3c5da566,
    name: "classroom-weekly-report"
  }, {
    path: "/classroom/work-request",
    component: _16c60288,
    name: "classroom-work-request"
  }, {
    path: "/community/insight",
    component: _31c0f70a,
    name: "community-insight"
  }, {
    path: "/community/lounge",
    component: _6c5f7b0d,
    name: "community-lounge"
  }, {
    path: "/events/comento_skt",
    component: _457c5139,
    name: "events-comento_skt"
  }, {
    path: "/events/data_quiz-2022",
    component: _d3a142c0,
    name: "events-data_quiz-2022"
  }, {
    path: "/events/hr_convert_con",
    component: _2dd8be80,
    name: "events-hr_convert_con"
  }, {
    path: "/events/referral",
    component: _66c7fb1c,
    name: "events-referral"
  }, {
    path: "/events/thanks-2023",
    component: _6854e9ba,
    name: "events-thanks-2023"
  }, {
    path: "/notice/create",
    component: _67aca248,
    name: "notice-create"
  }, {
    path: "/open-chat/link",
    component: _13bc0f0a,
    name: "open-chat-link"
  }, {
    path: "/product/b2b-gen-ai-class",
    component: _5cc2ec95,
    name: "product-b2b-gen-ai-class"
  }, {
    path: "/product/class",
    component: _2f7b0e44,
    name: "product-class"
  }, {
    path: "/product/externship",
    component: _59083334,
    name: "product-externship"
  }, {
    path: "/survey/community",
    component: _32837f61,
    name: "survey-community"
  }, {
    path: "/test-editor/tinymce",
    component: _824ad6de,
    name: "test-editor-tinymce"
  }, {
    path: "/vod/player",
    component: _2e0950d2,
    name: "vod-player"
  }, {
    path: "/class/on-air/list",
    component: _c9fc693a,
    name: "class-on-air-list"
  }, {
    path: "/class/pt/open",
    component: _0fac9ee7,
    name: "class-pt-open"
  }, {
    path: "/class/sesac/gangseo_dt2",
    component: _32de2b33,
    name: "class-sesac-gangseo_dt2"
  }, {
    path: "/class/sesac/gangseo_dt3",
    component: _707a4418,
    name: "class-sesac-gangseo_dt3"
  }, {
    path: "/class/sesac/seongdong_dt1",
    component: _004819a2,
    name: "class-sesac-seongdong_dt1"
  }, {
    path: "/class/sesac/yongsan_dt3",
    component: _753af87b,
    name: "class-sesac-yongsan_dt3"
  }, {
    path: "/class/vod/list",
    component: _4a247403,
    name: "class-vod-list"
  }, {
    path: "/classroom/path/submit",
    component: _58b2c48f,
    name: "classroom-path-submit"
  }, {
    path: "/classroom/weekly-report/submit",
    component: _05a52e20,
    name: "classroom-weekly-report-submit"
  }, {
    path: "/community/insight/create",
    component: _20d5d5e6,
    name: "community-insight-create"
  }, {
    path: "/community/lounge/create",
    component: _b37e91d8,
    name: "community-lounge-create"
  }, {
    path: "/kdc/apply/complete",
    component: _ebbe9a28,
    name: "kdc-apply-complete"
  }, {
    path: "/refund/camp/apply",
    component: _38b8b020,
    name: "refund-camp-apply"
  }, {
    path: "/refund/camp/holdon",
    component: _ea8e377c,
    name: "refund-camp-holdon"
  }, {
    path: "/refund/camp/list",
    component: _afa72e02,
    name: "refund-camp-list"
  }, {
    path: "/vod/player/checkplus",
    component: _4bdb00b6,
    name: "vod-player-checkplus"
  }, {
    path: "/class/pt/dashboard/woowa",
    component: _66677282,
    name: "class-pt-dashboard-woowa"
  }, {
    path: "/class/pt/pay/complete",
    component: _56c1424c,
    name: "class-pt-pay-complete"
  }, {
    path: "/class/vod/detail/sample/:no?",
    component: _06704064,
    name: "class-vod-detail-sample-no"
  }, {
    path: "/preview/class/on-air/detail/:no?",
    component: _4da93e9d,
    name: "preview-class-on-air-detail-no"
  }, {
    path: "/class/on-air/detail/:no?",
    component: _58244a64,
    name: "class-on-air-detail-no"
  }, {
    path: "/class/pt/list/:keyword?",
    component: _c3f6725e,
    name: "class-pt-list-keyword"
  }, {
    path: "/class/vod/detail/:no?",
    component: _3cc1a972,
    name: "class-vod-detail-no"
  }, {
    path: "/classroom/path/assignment/:no?",
    component: _3b35dfdc,
    name: "classroom-path-assignment-no"
  }, {
    path: "/classroom/pt/assignment/:no?",
    component: _bc5d2f5a,
    name: "classroom-pt-assignment-no"
  }, {
    path: "/vod/player/checkplus/:status",
    component: _440eecb8,
    name: "vod-player-checkplus-status"
  }, {
    path: "/class/pt/content/:title?/:classNo?",
    component: _29de5d50,
    name: "class-pt-content-title-classNo"
  }, {
    path: "/class/pt/contents/:top?/:class?",
    component: _04fb9037,
    name: "class-pt-contents-top-class"
  }, {
    path: "/vod/player/lecture/:lectureId?/:lessonId",
    component: _6ef877e1,
    name: "vod-player-lecture-lectureId-lessonId"
  }, {
    path: "/affiliate/edu-coupon/:encrypt",
    component: _2fbaa41f,
    name: "affiliate-edu-coupon-encrypt"
  }, {
    path: "/career/dreamverse/:partnerWithId",
    component: _4eec67ba,
    name: "career-dreamverse-partnerWithId"
  }, {
    path: "/career/recruit/:recruitIdWithCompanyName",
    component: _32967eb3,
    name: "career-recruit-recruitIdWithCompanyName"
  }, {
    path: "/class/sesac/:partnerWithRound?",
    component: _43a7e9fc,
    name: "class-sesac-partnerWithRound"
  }, {
    path: "/classroom/assignment/:no?",
    component: _3bc21a44,
    name: "classroom-assignment-no"
  }, {
    path: "/classroom/path/:no",
    component: _1f8b239f,
    name: "classroom-path-no"
  }, {
    path: "/classroom/pt/:no",
    component: _92323284,
    name: "classroom-pt-no"
  }, {
    path: "/community/insight/:title",
    component: _0a467900,
    name: "community-insight-title"
  }, {
    path: "/community/lounge/:title",
    component: _1d9b9f2e,
    name: "community-lounge-title"
  }, {
    path: "/forms/typeform/:id?",
    component: _6d59c41a,
    name: "forms-typeform-id"
  }, {
    path: "/kdc/apply/:lectureId?",
    component: _8680adac,
    name: "kdc-apply-lectureId"
  }, {
    path: "/kdc/progress/:lectureId",
    component: _5e6493c0,
    name: "kdc-progress-lectureId"
  }, {
    path: "/pt/live-class/:meetingId?",
    component: _6f2c99e4,
    name: "pt-live-class-meetingId"
  }, {
    path: "/affiliate/edu-coupon/:encrypt?/abandon",
    component: _c8b437f6,
    name: "affiliate-edu-coupon-encrypt-abandon"
  }, {
    path: "/affiliate/edu-coupon/:encrypt?/loading",
    component: _434d6a0c,
    name: "affiliate-edu-coupon-encrypt-loading"
  }, {
    path: "/classroom/pt/:no?/submit",
    component: _6b6b4342,
    name: "classroom-pt-no-submit"
  }, {
    path: "/kdc/progress/:lectureId?/registration",
    component: _5ccc3204,
    name: "kdc-progress-lectureId-registration"
  }, {
    path: "/classroom/path/:no?/detail/:campno?",
    component: _13159e34,
    name: "classroom-path-no-detail-campno"
  }, {
    path: "/kdc/progress/:lectureId?/guide/:step?",
    component: _e0649b74,
    name: "kdc-progress-lectureId-guide-step"
  }, {
    path: "/pt/attendance/:ptNo?/:week?",
    component: _2fc26a13,
    name: "pt-attendance-ptNo-week"
  }, {
    path: "/vod/lecture/:top?/:title?",
    component: _0bfedf23,
    name: "vod-lecture-top-title"
  }, {
    path: "/classroom/:no?",
    component: _2ff5032a,
    name: "classroom-no"
  }, {
    path: "/job-questions/:keyword?",
    component: _3cba5da6,
    name: "job-questions-keyword"
  }, {
    path: "/notice/:title",
    component: _446c0cf6,
    name: "notice-title"
  }, {
    path: "/secrecy/:product?",
    component: _ade9f862,
    name: "secrecy-product"
  }, {
    path: "/web-form/:id?",
    component: _8274799c,
    name: "web-form-id"
  }, {
    path: "/pay/:domain?/deposit/:partner?",
    component: _0b4f5677,
    name: "pay-domain-deposit-partner"
  }, {
    path: "/search/:product?/:keyword?",
    component: _0d42426c,
    name: "search-product-keyword"
  }, {
    path: "/shop/:product?/:id?",
    component: _f6d6ae4c,
    name: "shop-product-id"
  }, {
    path: "/payment/:partner?/:type?/complete",
    component: _0f122fb2,
    name: "payment-partner-type-complete"
  }, {
    path: "/apply/:domain?/:subDomain?/:productId?",
    component: _5212e78e,
    name: "apply-domain-subDomain-productId"
  }, {
    path: "/job-questions/:company?/:position?/:questionAndNo?",
    component: _85a7ec02,
    name: "job-questions-company-position-questionAndNo"
  }, {
    path: "/pay/:domain?/:subDomain?/:productId?",
    component: _36736c70,
    name: "pay-domain-subDomain-productId"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/edu/path/learn/:top/:camp",
    component: _252f95f5,
    name: "edu-path-learn-top-camp"
  }, {
    path: "/career/externship/:top/:camp",
    component: _252f95f5,
    name: "career-externship-top-camp"
  }, {
    path: "/kdigital/:top/:title",
    component: _0bfedf23,
    name: "kdigital-top-title"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
