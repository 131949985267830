import partition from 'lodash/partition';
import { createNamespacedHelpers } from 'vuex';

import { DREAMVERSE_PARTNER_TYPE } from '@/constants/career/dreamverse';

import { isProduction } from '@/plugins/constants';
import dayjs, { getTimeInAsia } from '@/plugins/dayjs';

import dreamverseDetailData from '@/static/data/dreamverse_detail.json';
import dreamverseJobsData from '@/static/data/dreamverse_jobs.json';
import dreamverseListData from '@/static/data/dreamverse_list.json';
import dreamverseNonParticipatingTargetData from '@/static/data/dreamverse_non_participating_target.json';
import dreamverseSiteData from '@/static/data/dreamverse_site.json';
import dreamverseVideoData from '@/static/data/dreamverse_video.json';
import internzCompanyData from '@/static/data/internz_company.json';
import internzDetailData from '@/static/data/internz_detail.json';
import internzJobs10 from '@/static/data/internz_jobs10.json';
import internzJobs11 from '@/static/data/internz_jobs11.json';
import internzJobs12 from '@/static/data/internz_jobs12.json';
import internzJobs13 from '@/static/data/internz_jobs13.json';
import internzJobs14 from '@/static/data/internz_jobs14.json';
import internzJobs3 from '@/static/data/internz_jobs3.json';
import internzJobs4 from '@/static/data/internz_jobs4.json';
import internzJobs5 from '@/static/data/internz_jobs5.json';
import internzJobs6 from '@/static/data/internz_jobs6.json';
import internzJobs7 from '@/static/data/internz_jobs7.json';
import internzJobs8 from '@/static/data/internz_jobs8.json';
import internzJobs9 from '@/static/data/internz_jobs9.json';

const JOBS_FOR_FOREIGN_ID = {
	'2be134d8-6988-40e1-8f5d-0bea556c9509': internzJobs3,
	'b6e75c20-2fcc-4f24-89b4-e43bf4cdea8c': internzJobs4,
	'65711a5a-08f5-4413-96a4-a82040244fa7': internzJobs5,
	'3b4502e0-abae-402d-846a-30f20b44904c': internzJobs6,
	'225ecf73-237e-49e6-ba86-90b88ff17649': internzJobs7,
	'47007846-27a7-4459-a7ab-5013ed55036b': internzJobs8,
	'5a09e87a-e279-4a7d-a4c3-4818be1dd9a2': internzJobs9,
	'713fec66-6dc0-4402-b06e-0ef18e4e76d6': internzJobs10,
	'8bfdf009-cb4b-45ab-b568-96c3fc4fe473': internzJobs11,
	'c9a5c9b3-3ce0-4859-b18e-42457c22821a': internzJobs12,
	'860b3206-1dad-46dd-bb65-ec13ff34f4ca': internzJobs13,
	'46a175e6-468f-4aa5-9a23-19ee7d34808d': internzJobs14,
};

const NAMESPACE = 'dreamverse';
export { NAMESPACE as NAMESPACE_DREAMVERSE };
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfDreamverse,
	mapMutations as mapMutationsOfDreamverse,
	mapActions as mapActionsOfDreamverse,
};

const CUSTOM_DREAMVERSE_NAMES = ['hansol-3', 'ybm-1', 'hansol-2', 'hansol-1', 'kiwon-1', 'kiwon-2', 'kiwon-3'];
export const CUSTOM_INTERNZ_YBM_ID = 'internz-ybm-14';
export const CUSTOM_DREAMVERSE_ITEMS = {
	'seohan-2': {
		title: '[2차] 서한그룹 드림버스컴퍼니',
		notice: `<ul>
			<li>사전직무교육 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일을 초과하여 수료기준에 미달하는 경우, 이후 일경험(가상형 인턴십)에는 참여 불가합니다.</li>
			<li>일경험(가상형 인턴십)은 일경험 전체 기간 중 80% 이상 출석해야 수료가 가능합니다. (100시간 중 80시간 이상)</li>
			<li>다른 참여자들의 성실한 참여와 일경험 업무 성과 창출에 심대하게 부정적인 영향을 끼친다고 판단되는 경우 참여 불가합니다.</li>
			<li>프로그램 중 중도 탈락 청년의 수당은 출석률에 비례한 지급 기준에 따라 지급됩니다.</li>
		</ul>`,
		nonParticipatingCondition: `<ul>
			[재직자]
			<li>프로그램 실시 신고 시점을 기준으로 고용보험전산망 피보험자 자격 기준으로 확인</li>
			<li>단, 주 30시간 미만 일자리에 근무하는 자는 미취업자로 간주하여 참여가능(근무시간이 명시된 근로계약서 제출 필요)</li>
			<br/>
			[사업자]
			<li>사업자등록증을 보유한 사업자(전년도 연매출 1억 5천만원 이상)</li>
			<li>단, 사업자등록을 한 경우에도, 휴업신고를 하는 등 실제 사업을 하지 아니하였음을 증명한 경우, 또는 소득금액증명원, 부가가치과세 표준증명원으로 전년도 사업소득이 1억 5천만원 미만임을 증명한 경우 참여 가능(소득증빙이 불가한 경우, 확인서를 받고 추후 증빙 제출)</li>
			<br/>
			[중복참여]
			<li>미래내일 일경험, 국민취업지원제도 일경험 프로그램을 포함하여 동일 연도에 최대 2회까지 일경험 프로그램(기업탐방형을 제외)에 참여할 수 있으나, 동시 또는 동일한 프로그램에는 재참여할 수 없으며, 자발적인 의사에 따라 중도 이탈한 경우에는 1회 참여한 것으로 봄</li>
			<li>질병·사고·재난 등 본인의 귀책사유 없이 발생한 중도이탈의 경우에는 참여횟수에 불포함(동일연도 기준: 2024.03.01 ~ 2025.02.28)</li>
			<br/>
			[타 사업과의 중복 참여]
			<li>타 정부부처, 지자체 등 지원 사업 참여자가 중복 참여하고자 할 경우 교육시간이 중복되지 않는 경우 참여 가능</li>
			<li>교육 시간의 경우 사업 운영 과정에서 지정된 시간내에 행해지는 집체 교육, 실시간 온라인 교육, 멘토링 등 모든 교육의 형태를 의미</li>
			<li>단, 중복 참여하는 경우 타 지원사업의 세부 실시 조건에 따라 교육 시간 중복 여부와 상관없이 참여 및 수당 지급이 제한 될 수 있음</li>
		</ul>`,
	},
	'humax-4': {
		title: '[4차] 휴맥스 드림버스컴퍼니',
		notice: `<ul>
			<li>사전직무교육 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일을 초과하여 수료기준에 미달하는 경우, 이후 일경험(가상형 인턴십)에는 참여 불가합니다.</li>
			<li>일경험(가상형 인턴십)은 일경험 전체 기간 중 80% 이상 출석해야 수료가 가능합니다. (100시간 중 80시간 이상)</li>
			<li>다른 참여자들의 성실한 참여와 일경험 업무 성과 창출에 심대하게 부정적인 영향을 끼친다고 판단되는 경우 참여 불가합니다.</li>
			<li>프로그램 중 중도 탈락 청년의 수당은 출석률에 비례한 지급 기준에 따라 지급됩니다.</li>
		</ul>`,
		nonParticipatingCondition: `<ul>
			[재직자]
			<li>프로그램 실시 신고 시점을 기준으로 고용보험전산망 피보험자 자격 기준으로 확인</li>
			<li>단, 주 30시간 미만 일자리에 근무하는 자는 미취업자로 간주하여 참여가능(근무시간이 명시된 근로계약서 제출 필요)</li>
			<br/>
			[사업자]
			<li>사업자등록증을 보유한 사업자(전년도 연매출 1억 5천만원 이상)</li>
			<li>단, 사업자등록을 한 경우에도, 휴업신고를 하는 등 실제 사업을 하지 아니하였음을 증명한 경우, 또는 소득금액증명원, 부가가치과세 표준증명원으로 전년도 사업소득이 1억 5천만원 미만임을 증명한 경우 참여 가능(소득증빙이 불가한 경우, 확인서를 받고 추후 증빙 제출)</li>
			<br/>
			[중복참여]
			<li>미래내일 일경험, 국민취업지원제도 일경험 프로그램을 포함하여 동일 연도에 최대 2회까지 일경험 프로그램(기업탐방형을 제외)에 참여할 수 있으나, 동시 또는 동일한 프로그램에는 재참여할 수 없으며, 자발적인 의사에 따라 중도 이탈한 경우에는 1회 참여한 것으로 봄</li>
			<li>질병·사고·재난 등 본인의 귀책사유 없이 발생한 중도이탈의 경우에는 참여횟수에 불포함(동일연도 기준: 2024.03.01 ~ 2025.02.28)</li>
			<br/>
			[타 사업과의 중복 참여]
			<li>타 정부부처, 지자체 등 지원 사업 참여자가 중복 참여하고자 할 경우 교육시간이 중복되지 않는 경우 참여 가능</li>
			<li>교육 시간의 경우 사업 운영 과정에서 지정된 시간내에 행해지는 집체 교육, 실시간 온라인 교육, 멘토링 등 모든 교육의 형태를 의미</li>
			<li>단, 중복 참여하는 경우 타 지원사업의 세부 실시 조건에 따라 교육 시간 중복 여부와 상관없이 참여 및 수당 지급이 제한 될 수 있음</li>
		</ul>`,
	},
	'kiwon-1': {
		title: '비수도권 지역인재 특화트랙<br />기원테크 드림버스컴퍼니',
		notice: `<ul>
			<li>사전직무교육 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일을 초과하여 수료기준에 미달하는 경우, 이후 일경험(가상형 인턴십)에는
			참여 불가합니다.</li>
			<li>가상형 인턴십 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일이 초과되는 경우, 다른 참여자들의 성실한 참여와 일경험 업무 성과
			창출에 심대하게 부정적인 영향을 끼친다고 판단되는 경우 참여 불가합니다.</li>
			<li>프로그램 중 중도 탈락 청년의 수당은 출석률에 비례한 지급 기준에 따라 지급됩니다.</li>
		</ul>`,
		nonParticipatingCondition: `<ul>
			[재직자]
			<li>프로그램 실시 신고 시점을 기준으로 고용보험전산망 피보험자 자격 기준으로 확인</li>
			<li>단, 주 30시간 미만 일자리에 근무하는 자는 미취업자로 간주하여 참여가능(근무시간이 명시된 근로계약서 제출 필요)</li>
			<br/>
			[사업자]
			<li>사업자등록증을 보유한 사업자(전년도 연매출 1억 5천만원 이상)</li>
			<li>단, 사업자등록을 한 경우에도, 휴업신고를 하는 등 실제 사업을 하지 아니하였음을 증명한 경우, 또는 소득금액증명원, 부가가치과세 표준증명원으로 전년도 사업소득이 1억 5천만원 미만임을 증명한 경우 참여 가능(소득증빙이 불가한 경우, 확인서를 받고 추후 증빙 제출)</li>
			<br/>
			[중복참여]
			<li>미래내일 일경험, 국민취업지원제도 일경험 프로그램을 포함하여 동일 연도에 최대 2회까지 일경험 프로그램(기업탐방형을 제외)에 참여할 수 있으나, 동시 또는 동일한 프로그램에는 재참여할 수 없으며, 자발적인 의사에 따라 중도 이탈한 경우에는 1회 참여한 것으로 봄</li>
			<li>질병·사고·재난 등 본인의 귀책사유 없이 발생한 중도이탈의 경우에는 참여횟수에 불포함(동일연도 기준: 2024.03.01 ~ 2025.02.28)</li>
			<br/>
			[타 사업과의 중복 참여]
			<li>타 정부부처, 지자체 등 지원 사업 참여자가 중복 참여하고자 할 경우 교육시간이 중복되지 않는 경우 참여 가능</li>
			<li>교육 시간의 경우 사업 운영 과정에서 지정된 시간내에 행해지는 집체 교육, 실시간 온라인 교육, 멘토링 등 모든 교육의 형태를 의미</li>
			<li>단, 중복 참여하는 경우 타 지원사업의 세부 실시 조건에 따라 교육 시간 중복 여부와 상관없이 참여 및 수당 지급이 제한 될 수 있음</li>
		</ul>`,
	},
	'kiwon-2': {
		title: '니트청년 특화트랙<br />기원테크 드림버스컴퍼니',
		vpBox: {
			title: '니트청년 드림버스컴퍼니는 <br />취업에 어려움을 겪는 청년들을 위한 <br />재택 참여형 인턴십 프로그램입니다.',
			items: [
				{
					title: '집에서 경험하는 일경험',
					description:
						'메타버스에 구축된 기원테크에서 공간의 제약 없이 기업이 제공하는 인턴십을 집에서 참여할 수 있습니다.',
				},
				{
					title: '공통교육과 심화교육',
					description:
						'자기 이해와 다양한 진로 탐색을 통해 성향에 맞는 일을 파악하고 적성에 맞는 직무를 선택할 수 있습니다.',
				},
				{
					title: '조직문화 적응과 실무경험',
					description:
						'기원테크의 조직 문화를 경험하고 현직자와 함께 현업 과제를 수행하며 실무 경험을 할 수 있습니다.',
				},
			],
		},
		notice: `<ul>
			<li>사전직무교육 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일을 초과하여 수료기준에 미달하는 경우, 이후 일경험(가상형 인턴십)에는
			참여 불가합니다.</li>
			<li>가상형 인턴십 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일이 초과되는 경우, 다른 참여자들의 성실한 참여와 일경험 업무 성과
			창출에 심대하게 부정적인 영향을 끼친다고 판단되는 경우 참여 불가합니다.</li>
			<li>프로그램 중 중도 탈락 청년의 수당은 출석률에 비례한 지급 기준에 따라 지급됩니다.</li>
		</ul>`,
		nonParticipatingCondition: `<ul>
		[재직자]
		<li>프로그램 실시 신고 시점을 기준으로 고용보험전산망 피보험자 자격 기준으로 확인</li>
		<li>단, 주 30시간 미만 일자리에 근무하는 자는 미취업자로 간주하여 참여가능(근무시간이 명시된 근로계약서 제출 필요)</li>
		<br/>
		[사업자]
		<li>사업자등록증을 보유한 사업자(전년도 연매출 1억 5천만원 이상)</li>
		<li>단, 사업자등록을 한 경우에도, 휴업신고를 하는 등 실제 사업을 하지 아니하였음을 증명한 경우, 또는 소득금액증명원, 부가가치과세 표준증명원으로 전년도 사업소득이 1억 5천만원 미만임을 증명한 경우 참여 가능(소득증빙이 불가한 경우, 확인서를 받고 추후 증빙 제출)</li>
		<br/>
		[중복참여]
		<li>미래내일 일경험, 국민취업지원제도 일경험 프로그램을 포함하여 동일 연도에 최대 2회까지 일경험 프로그램(기업탐방형을 제외)에 참여할 수 있으나, 동시 또는 동일한 프로그램에는 재참여할 수 없으며, 자발적인 의사에 따라 중도 이탈한 경우에는 1회 참여한 것으로 봄</li>
		<li>질병·사고·재난 등 본인의 귀책사유 없이 발생한 중도이탈의 경우에는 참여횟수에 불포함(동일연도 기준: 2024.03.01 ~ 2025.02.28)</li>
		<br/>
		[타 사업과의 중복 참여]
		<li>타 정부부처, 지자체 등 지원 사업 참여자가 중복 참여하고자 할 경우 교육시간이 중복되지 않는 경우 참여 가능</li>
		<li>교육 시간의 경우 사업 운영 과정에서 지정된 시간내에 행해지는 집체 교육, 실시간 온라인 교육, 멘토링 등 모든 교육의 형태를 의미</li>
		<li>단, 중복 참여하는 경우 타 지원사업의 세부 실시 조건에 따라 교육 시간 중복 여부와 상관없이 참여 및 수당 지급이 제한 될 수 있음</li>
	</ul>`,
	},
	'kiwon-3': {
		title: '비수도권 지역인재 특화트랙<br />기원테크 드림버스컴퍼니',
		notice: `<ul>
			<li>사전직무교육 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일을 초과하여 수료기준에 미달하는 경우, 이후 일경험(가상형 인턴십)에는 참여 불가합니다.</li>
			<li>일경험(가상형 인턴십)은 일경험 전체 기간 중 80% 이상 출석해야 수료가 가능합니다. (100시간 중 80시간 이상)</li>
			<li>다른 참여자들의 성실한 참여와 일경험 업무 성과 창출에 심대하게 부정적인 영향을 끼친다고 판단되는 경우 참여 불가합니다.</li>
			<li>프로그램 중 중도 탈락 청년의 수당은 출석률에 비례한 지급 기준에 따라 지급됩니다.</li>
		</ul>`,
		nonParticipatingCondition: `<ul>
		[재직자]
		<li>프로그램 실시 신고 시점을 기준으로 고용보험전산망 피보험자 자격 기준으로 확인</li>
		<li>단, 주 30시간 미만 일자리에 근무하는 자는 미취업자로 간주하여 참여가능(근무시간이 명시된 근로계약서 제출 필요)</li>
		<br/>
		[사업자]
		<li>사업자등록증을 보유한 사업자(전년도 연매출 1억 5천만원 이상)</li>
		<li>단, 사업자등록을 한 경우에도, 휴업신고를 하는 등 실제 사업을 하지 아니하였음을 증명한 경우, 또는 소득금액증명원, 부가가치과세 표준증명원으로 전년도 사업소득이 1억 5천만원 미만임을 증명한 경우 참여 가능(소득증빙이 불가한 경우, 확인서를 받고 추후 증빙 제출)</li>
		<br/>
		[중복참여]
		<li>미래내일 일경험, 국민취업지원제도 일경험 프로그램을 포함하여 동일 연도에 최대 2회까지 일경험 프로그램(기업탐방형을 제외)에 참여할 수 있으나, 동시 또는 동일한 프로그램에는 재참여할 수 없으며, 자발적인 의사에 따라 중도 이탈한 경우에는 1회 참여한 것으로 봄</li>
		<li>질병·사고·재난 등 본인의 귀책사유 없이 발생한 중도이탈의 경우에는 참여횟수에 불포함(동일연도 기준: 2024.03.01 ~ 2025.02.28)</li>
		<br/>
		[타 사업과의 중복 참여]
		<li>타 정부부처, 지자체 등 지원 사업 참여자가 중복 참여하고자 할 경우 교육시간이 중복되지 않는 경우 참여 가능</li>
		<li>교육 시간의 경우 사업 운영 과정에서 지정된 시간내에 행해지는 집체 교육, 실시간 온라인 교육, 멘토링 등 모든 교육의 형태를 의미</li>
		<li>단, 중복 참여하는 경우 타 지원사업의 세부 실시 조건에 따라 교육 시간 중복 여부와 상관없이 참여 및 수당 지급이 제한 될 수 있음</li>
	</ul>`,
	},
};

export const FILTERED_DREAMVERSE_LIST = 'FILTERED_DREAMVERSE_LIST';
export const DREAMVERSE_ITEM = 'DREAMVERSE_ITEM';
export const DREAMVERSE_JOBS = 'DREAMVERSE_JOBS';
export const DREAMVERSE_VIDEOS = 'DREAMVERSE_VIDEOS';
export const DREAMVERSE_SITES = 'DREAMVERSE_SITES';
export const DREAMVERSE_NON_PARTICIPATING_TARGET = 'DREAMVERSE_NON_PARTICIPATING_TARGET';
export const DREAMVERSE_FOREIGN_ID = 'DREAMVERSE_FOREIGN_ID';
export const DREAMVERSE_VERSION = 'DREAMVERSE_VERSION';
export const IS_CUSTOM_DREAMVERSE = 'IS_CUSTOM_DREAMVERSE';
export const IS_INTERNZ = 'IS_INTERNZ';
export const INTERNZ_COMPANY = 'INTERNZ_COMPANY';
export const INTERNZ_JOBS = 'INTERNZ_JOBS';
export const IS_KOKKIRI_GONGJANG = 'IS_KOKKIRI_GONGJANG';

export const SET_DREAMVERSE_LIST = 'SET_DREAMVERSE_LIST';
export const SET_DREAMVERSE_ITEM = 'SET_DREAMVERSE_ITEM';
export const SET_DREAMVERSE_JOBS = 'SET_DREAMVERSE_JOBS';
export const SET_DREAMVERSE_VIDEOS = 'SET_DREAMVERSE_VIDEOS';
export const SET_DREAMVERSE_SITES = 'SET_DREAMVERSE_SITES';
export const SET_DREAMVERSE_FOREIGN_ID = 'SET_DREAMVERSE_FOREIGN_ID';
export const SET_DREAMVERSE_NON_PARTICIPATING_TARGET = 'SET_DREAMVERSE_NON_PARTICIPATING_TARGET';
export const SET_INTERNZ_COMPANY = 'SET_INTERNZ_COMPANY';
export const SET_INTERNZ_JOBS = 'SET_INTERNZ_JOBS';

export const LOAD_DREAMVERSE_LIST = 'LOAD_DREAMVERSE_LIST';
export const LOAD_DREAMVERSE_ITEM = 'LOAD_DREAMVERSE_ITEM';
export const RESET_DREAMVERSE_DETAIL_INFO = 'RESET_DREAMVERSE_DETAIL_INFO';

export const state = () => ({
	dreamverseList: [],
	dreamverseItem: {},
	dreamverseJobs: [],
	dreamverseVideos: [],
	dreamverseSites: [],
	dreamverseNonParticipatingTarget: null,
	dreamverseForeignID: null,
	internzCompany: [],
	internzJobs: [],
	test: [],
});

export const getters = {
	[FILTERED_DREAMVERSE_LIST]: state => {
		const list = isProduction ? state.dreamverseList.filter(item => item?.status === '공개') : state.dreamverseList;

		const [active, inactive] = partition(list, item =>
			getTimeInAsia().isSameOrBefore(dayjs(item.recruit_end_date).endOf('day')),
		);

		active.sort((a, b) => {
			const recruitEndDateComparison = dayjs(a.recruit_end_date).isSameOrBefore(dayjs(b.recruit_end_date))
				? -1
				: 1;
			if (dayjs(a.recruit_end_date).isSame(dayjs(b.recruit_end_date))) {
				// 만약 날짜가 같으면 round를 내림차순으로 정렬
				return b.round - a.round;
			}
			return recruitEndDateComparison;
		});

		return [...active, ...inactive];
	},
	[DREAMVERSE_ITEM]: state => state.dreamverseItem,
	[DREAMVERSE_JOBS]: state => state.dreamverseJobs,
	[DREAMVERSE_VIDEOS]: state => state.dreamverseVideos,
	[DREAMVERSE_SITES]: state => state.dreamverseSites,
	[DREAMVERSE_NON_PARTICIPATING_TARGET]: state => state.dreamverseNonParticipatingTarget,
	[DREAMVERSE_FOREIGN_ID]: state => state.dreamverseForeignID,
	[IS_CUSTOM_DREAMVERSE]: (state, getters) => {
		const name = state.dreamverseItem.name;
		if (getters[IS_INTERNZ]) {
			return true;
		}
		return CUSTOM_DREAMVERSE_NAMES.includes(name);
	},
	[DREAMVERSE_VERSION]: state => parseInt(state.dreamverseItem.version),
	[IS_INTERNZ]: state => state.dreamverseItem.partner_type === DREAMVERSE_PARTNER_TYPE.INTERNZ,
	[INTERNZ_COMPANY]: state => state.internzCompany,
	[INTERNZ_JOBS]: state => state.internzJobs,
	[IS_KOKKIRI_GONGJANG]: state => state.dreamverseItem.foreign_id === '713fec66-6dc0-4402-b06e-0ef18e4e76d6',
};

export const mutations = {
	[SET_DREAMVERSE_LIST]: (state, payload) => {
		state.dreamverseList = payload;
	},
	[SET_DREAMVERSE_ITEM]: (state, payload) => {
		if (!payload) {
			return null;
		}
		return (state.dreamverseItem = { ...state.dreamverseItem, ...payload });
	},
	[SET_DREAMVERSE_JOBS]: (state, payload) => {
		state.dreamverseJobs = payload;
	},
	[SET_DREAMVERSE_VIDEOS]: (state, payload) => {
		state.dreamverseVideos = payload;
	},
	[SET_DREAMVERSE_SITES]: (state, payload) => {
		state.dreamverseSites = payload;
	},
	[SET_DREAMVERSE_NON_PARTICIPATING_TARGET]: (state, payload) => {
		state.dreamverseNonParticipatingTarget = payload;
	},
	[SET_DREAMVERSE_FOREIGN_ID]: (state, payload) => {
		state.dreamverseForeignID = payload;
	},
	[SET_INTERNZ_COMPANY]: (state, payload) => {
		state.internzCompany = payload;
	},
	[SET_INTERNZ_JOBS]: (state, payload) => {
		state.internzJobs = payload;
	},
};

export const actions = {
	[LOAD_DREAMVERSE_LIST]({ commit }) {
		try {
			// const { data } = await this.$api.notion.getDreamverseList();
			const list = dreamverseListData.results.map(
				({
					id,
					properties: {
						name,
						partner_name,
						gtm_partner_name,
						partner_type,
						round,
						url,
						thumbnail,
						jobs,
						recruit_end_date,
						status,
					},
				}) => {
					try {
						return {
							id,
							name: name.title[0].plain_text,
							partner_name: partner_name.select?.name,
							gtm_partner_name: gtm_partner_name.select?.name,
							partner_type: partner_type.select?.name,
							round: round.number,
							url: url.formula.string,
							thumbnail: thumbnail?.url,
							jobs: jobs.multi_select.map(({ name }) => name),
							recruit_end_date: recruit_end_date.rich_text[0].text.content,
							status: status.select?.name,
						};
					} catch (error) {
						return {
							status: '비공개',
						};
					}
				},
			);
			commit(SET_DREAMVERSE_LIST, list);
		} catch (error) {
			console.error('LOAD_DREAMVERSE_LIST error: ', error);
		}
	},
	loadInternzDetail(_, foreignID) {
		// const { data } = await this.$api.notion.getInternzDetail({ foreign_id: foreignID });
		return internzDetailData.results
			.filter(item => item.properties.foreign_id.relation[0].id === foreignID)
			.map(
				({
					properties: {
						dominant_color,
						apply_url,
						faq,
						total_company,
						version,
						vp_text,
						vp_work_location,
						vp_experience_title,
						vp_experience_description,
						weeks,
						recruit_start_date,
						paper_screening_pass_date,
						interview_start_date,
						interview_end_date,
						final_pass_date,
						pre_education_start_date,
						pre_education_end_date,
						work_experience_date,
					},
				}) => {
					return {
						dominant_color: dominant_color.title[0].plain_text,
						apply_url: apply_url.url,
						faq: faq.url,
						foreign_id: foreignID,
						total_company: total_company.number,
						version: version.select?.name,
						vp_text: vp_text.rich_text[0]?.plain_text,
						vp_work_location: vp_work_location.rich_text[0]?.plain_text,
						vp_experience_title: vp_experience_title.rich_text[0]?.plain_text,
						vp_experience_description: vp_experience_description.rich_text[0]?.plain_text,
						weeks: weeks.number,
						recruit_start_date: recruit_start_date.rich_text[0]?.plain_text,
						paper_screening_pass_date: paper_screening_pass_date.rich_text[0]?.plain_text,
						interview_start_date: interview_start_date.rich_text[0]?.plain_text,
						interview_end_date: interview_end_date.rich_text[0]?.plain_text,
						final_pass_date: final_pass_date.rich_text[0]?.plain_text,
						pre_education_start_date: pre_education_start_date.rich_text[0]?.plain_text,
						pre_education_end_date: pre_education_end_date.rich_text[0]?.plain_text,
						work_experience_date: work_experience_date.rich_text[0]?.plain_text,
					};
				},
			);
	},
	loadInternzCompany({ commit }, foreignID) {
		// const { data } = await this.$api.notion.getInternzCompany({ foreign_id: foreignID });
		const companyList = internzCompanyData.results
			.filter(item => item.properties.foreign_id.relation.some(({ id }) => id === foreignID))
			.map(({ id, properties: { company, description, location, logo_url, foreign_id } }) => {
				return {
					id,
					foreign_id: foreign_id.relation.map(({ id }) => id),
					company: company.title[0].plain_text,
					description: description.rich_text[0]?.plain_text,
					location: location.select?.name,
					logo_url: logo_url?.url,
				};
			});
		commit(SET_INTERNZ_COMPANY, companyList);
	},
	loadInternzJobs({ commit }, foreignID) {
		// const { data } = await this.$api.notion.getInternzJobs({ foreign_id: foreignID });
		const jobs = JOBS_FOR_FOREIGN_ID[foreignID]?.results.map(
			({ properties: { foreign_company, job, description, person_limit, order, highlight } }) => {
				return {
					foreign_id: foreignID,
					foreign_company: foreign_company?.relation[0].id,
					job: job.title[0]?.plain_text,
					description: description.rich_text[0]?.plain_text,
					person_limit: person_limit?.number,
					order: order?.number,
					highlight: highlight.select?.name,
				};
			},
		);
		commit(SET_INTERNZ_JOBS, jobs);
	},
	loadDreamverseDetail(_, foreignID) {
		// const { data } = await this.$api.notion.getDreamverseDetail({ foreign_id: foreignID });
		const data = dreamverseDetailData.results.filter(
			item => item.properties.foreign_id.relation[0].id === foreignID,
		);
		return data.map(
			({
				properties: {
					dominant_color,
					apply_url,
					recruit_start_date,
					paper_screening_pass_date,
					final_pass_date,
					pre_education_start_date,
					pre_education_end_date,
					main_education_start_date,
					main_education_end_date,
					final_education_date,
					version,
					foreign_id,
				},
			}) => {
				return {
					dominant_color: dominant_color.title[0].plain_text,
					apply_url: apply_url?.url,
					recruit_start_date: recruit_start_date.rich_text[0]?.plain_text,
					paper_screening_pass_date: paper_screening_pass_date.rich_text[0]?.plain_text,
					final_pass_date: final_pass_date.rich_text[0]?.plain_text,
					pre_education_start_date: pre_education_start_date.rich_text[0]?.plain_text,
					pre_education_end_date: pre_education_end_date.rich_text[0]?.plain_text,
					main_education_start_date: main_education_start_date.rich_text[0]?.plain_text,
					main_education_end_date: main_education_end_date.rich_text[0]?.plain_text,
					final_education_date: final_education_date.rich_text[0]?.plain_text,
					version: version.select?.name,
					foreign_id: foreign_id.relation[0].id,
				};
			},
		);
	},
	loadDreamverseJobs({ commit }, foreignID) {
		try {
			// const { data } = await this.$api.notion.getDreamverseJobs({ foreign_id: foreignID });
			const jobs = dreamverseJobsData.results
				.filter(item => item.properties.foreign_id.relation[0]?.id === foreignID)
				.map(({ properties: { job, company, location, person_limit, description, highlight, foreign_id } }) => {
					return {
						job: job.title[0].plain_text,
						company: company.select?.name,
						location: location.select?.name,
						person_limit: person_limit.number,
						description: description.rich_text
							.reduce((prev, curr) => [...prev, curr.plain_text], [])
							.join(''),
						highlight: highlight.select?.name,
						foreign_id: foreign_id.relation.map(({ id }) => id),
					};
				});

			commit(SET_DREAMVERSE_JOBS, jobs);
		} catch (error) {
			console.error('loadDreamverseJobs error: ', error);
		}
	},
	loadDreamverseVideos({ commit }, foreignID) {
		try {
			// const { data } = await this.$api.notion.getDreamverseVideos({ foreign_id: foreignID });
			const dreamverseVideos = dreamverseVideoData.results
				.filter(item => item.properties.foreign_id?.relation.some(({ id }) => id === foreignID))
				.map(({ properties: { title, url, foreign_id } }) => {
					return {
						title: title.title[0].plain_text,
						url: url.url,
						foreign_id: foreign_id.relation.map(({ id }) => id),
					};
				});

			commit(SET_DREAMVERSE_VIDEOS, dreamverseVideos);
		} catch (error) {
			console.error('loadDreamverseVideos error: ', error);
		}
	},
	async [LOAD_DREAMVERSE_ITEM]({ commit, dispatch, getters }, { foreignID, partnerType }) {
		try {
			if (!foreignID) {
				return;
			}

			if (partnerType === DREAMVERSE_PARTNER_TYPE.INTERNZ) {
				const item = await dispatch('loadInternzDetail', foreignID);
				commit(SET_DREAMVERSE_ITEM, item[0]);
				await dispatch('loadInternzCompany', foreignID);
				await dispatch('loadInternzJobs', foreignID);
			} else {
				const item = await dispatch('loadDreamverseDetail', foreignID);
				commit(SET_DREAMVERSE_ITEM, item[0]);
				await dispatch('loadDreamverseVideos', foreignID);
				await dispatch('loadDreamverseSites', foreignID);
				await dispatch('loadDreamverseNonParticipatingTarget', foreignID);
				if (!getters[IS_CUSTOM_DREAMVERSE]) {
					await dispatch('loadDreamverseJobs', foreignID);
				}
			}
		} catch (error) {
			console.error('LOAD_DREAMVERSE_ITEM error: ', error);
		}
	},
	async loadDreamverseSites({ commit }, foreignID) {
		try {
			// const { data } = await this.$api.notion.getDreamverseSites({ foreign_id: foreignID });
			const data = await dreamverseSiteData.results.filter(item =>
				item.properties.foreign_id.relation.some(relationItem => relationItem.id === foreignID),
			);
			if (data?.length === 0) {
				return;
			}

			const dreamverseSites = data.map(({ properties: { home, youtube, blog } }) => {
				return {
					home: home?.url,
					youtube: youtube?.url,
					blog: blog?.url,
				};
			});

			commit(SET_DREAMVERSE_SITES, dreamverseSites[0]);
		} catch (error) {
			console.error('loadDreamverseSites error: ', error);
		}
	},
	async loadDreamverseNonParticipatingTarget({ commit }, foreignID) {
		try {
			// const { data } = await this.$api.notion.getDreamverseNonParticipatingTarget({ foreign_id: foreignID });
			const data = await dreamverseNonParticipatingTargetData.results.filter(item =>
				item.properties.foreign_id.relation.some(relationItem => relationItem.id === foreignID),
			);
			if (data?.length === 0) {
				return;
			}

			const result = data.map(({ properties: { non_participating_target } }) => {
				return non_participating_target.rich_text
					.reduce((prev, curr) => [...prev, curr.plain_text], [])
					.join('');
			});
			commit(SET_DREAMVERSE_NON_PARTICIPATING_TARGET, result[0]);
		} catch (error) {
			console.error('loadDreamverseNonParticipatingTarget error: ', error);
		}
	},
	[RESET_DREAMVERSE_DETAIL_INFO]({ commit }) {
		commit(SET_DREAMVERSE_FOREIGN_ID, null);
		commit(SET_DREAMVERSE_NON_PARTICIPATING_TARGET, null);
		commit(SET_DREAMVERSE_ITEM, {});
		commit(SET_DREAMVERSE_JOBS, []);
		commit(SET_DREAMVERSE_VIDEOS, []);
		commit(SET_DREAMVERSE_SITES, []);
		commit(SET_INTERNZ_COMPANY, []);
		commit(SET_INTERNZ_JOBS, []);
	},
};
