const LIVE_CLASS_PREFIX_URL = `/api/edu/live-class`;

export default axios => ({
	/**
	 * 라이브 클래스 진행중인지 여부
	 * */
	fetchIsMeetingLive(campNo) {
		return axios.post(`${LIVE_CLASS_PREFIX_URL}/ready/${campNo}`);
	},
	/**
	 * 라이브 클래스 zoom 생성하기
	 * 알림톡 나감
	 * */
	createLiveMeeting(campNo) {
		return axios.post(`${LIVE_CLASS_PREFIX_URL}/create/${campNo}`);
	},
	/*
	 * 참여할 강의의 title, week 가져오기
	 * */
	getLiveClassMeetingInfo(params) {
		return axios.get(`${LIVE_CLASS_PREFIX_URL}/meeting`, { params });
	},
	/*
	 * 라이브 클래스 참여하기
	 * */
	postLiveClassAttendance(data) {
		return axios.post(`${LIVE_CLASS_PREFIX_URL}/attendance`, data);
	},
	/*
	 * 라이브 클래스 휴대폰 번호로 참여하기
	 * data: {
	 * 	meeting_id,
	 * 	mobile
	 * }
	 * */
	postLiveClassAttendanceByPhoneNumber(data) {
		return axios.post(`${LIVE_CLASS_PREFIX_URL}/attendance-mobile`, data);
	},
	/*
	 * 출석체크할 캠프 정보 반환
	 * data.code === 200
	 * campInfo :{
	 *	 	"end_at",
	 *	 	"start_at",
	 * 		"title",
	 * 		"week"
	 * }
	 * 멘토가 출첵할 수 있는 멘티 리스트 (출석, 미출석, 줌 자동출석)
	 * list: {
	 * 		attended : [],
	 * 		not_attended : [],
	 * 		mentee_zoom : []
	 * }
	 ******************************
	 * data.code === 204
	 * 세션 시작으로부터 3일이 지나서 링크 만료
	 * */
	getAttendanceCampInfo(campNo, week) {
		return axios.get(`${LIVE_CLASS_PREFIX_URL}/mentor/attendance/allMenteeInfo/${campNo}/${week}`);
	},
	/*
	 * 멘토가 userNo 배열을 보내서 출석체크를 함.
	 * data: {
	 * users: [userNo, userNo, ...]
	 * }
	 * */
	checkMenteeAttendance(data, campNo, week) {
		return axios.post(`${LIVE_CLASS_PREFIX_URL}/mentor/attendance/check/${campNo}/${week}`, data);
	},
	/*
	 * 멘티 자신의 출석체크 정보 반환
	 * list: [attendance, ...]
	 * attendance: {
	 *   camp_no,
	 *   week,
	 *   start_at: 해당 세션 시작,
	 *   end_at: 해당 세션 끝,
	 *   checkable: 출석할 수 있는지,
	 *   via: 출석체크한 유저,
	 * }
	 * ******************************
	 * checkable
	 * - 출석체크 안되어있고, 세션 이후면서, 출석 체크 마감 날짜가 지나지 않았으면 true
	 * */
	getMyAttendances(campNo) {
		return axios.get(`${LIVE_CLASS_PREFIX_URL}/mentee/attendance/check/${campNo}`);
	},
	/*
	 * 멘티가 날짜 데이터를 보내서 자신의 출석을 체크함
	 * */
	checkMyAttendance(data, campNo, week) {
		return axios.post(`${LIVE_CLASS_PREFIX_URL}/mentee/attendance/check/${campNo}/${week}`, data);
	},
	/*
	 * 멘티가 강의에 참여할 때, 현재 auth로 참여할 수 있는지 체크함
	 * data: {
	 *  meeting_id,
	 * }
	 * */
	checkAttendancePossibility(data) {
		return axios.post(`${LIVE_CLASS_PREFIX_URL}/can-attendance`, data);
	},
	/*
	 * 멘티가 강의에 참여할 때, 현재 휴대폰 번호로 참여할 수 있는지 체크함
	 * data: {
	 *  meeting_id,
	 *  mobile,
	 * }
	 * */
	checkAttendancePossibilityByPhoneNumber(data) {
		return axios.post(`/api/classroom/live-class/can-attendance-mobile`, data);
	},
});
