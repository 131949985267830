
import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'MyPageSettingProfileGradeProgressBar',
	props: {
		gradeProgress: {
			type: Number,
			default: 0,
		},
		isLoadedProfileInfo: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Skeleton,
	},
};
