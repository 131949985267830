
import { mapGetters } from 'vuex';

import { timelineCoreMenu } from '@/constants/community';
import { INSIGHT_PATH, LOUNGE_PATH } from '@/constants/paths';

import { cdnImageBaseUrl, INSIGHT, LOUNGE } from '@/plugins/constants';

import { isEmptyData } from '@/utils/utils';

import { IS_OPEN_NOTI_DRAWER, mapGettersOfLayout, IS_SHOW_SEARCH_DROPDOWN } from '@/store/layout';
import {
	GET_ROUTE_NAME,
	ROUTE_NAME_FOR_COMMUNITY_LOUNGE,
	ROUTE_NAME_FOR_COMMUNITY_INSIGHT,
	mapGettersOfLoading,
} from '@/store/loading';

import CaseListServiceButton from '@/components/case/list/main/molecules/CaseListServiceButton.vue';
import UserProfileSection from '@/components/case/list/main/molecules/UserProfileSection.vue';
import MembershipStatusSection from '@/components/case/list/main/organisms/MembershipStatusSection.vue';
import CommonLoginModal from '@/components/common/molecules/login/CommonLoginModal.vue';
import ProfileImagePopover from '@/components/common/molecules/ProfileImagePopover.vue';
import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

import caseMixin from '@/mixins/caseMixin';
import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import { profileImageMixin } from '@/mixins/profileImageMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'CaseListSideBox',
	mixins: [profileImageMixin, routeMixin, userInfoMixin, caseMixin, communityGtmTriggerMixin],
	data() {
		return {
			isShowLoginModal: false,
			timelineCoreMenu: Object.freeze(timelineCoreMenu),
		};
	},
	computed: {
		...mapGetters(['userProfileImage', 'isUser']),
		...mapGettersOfLayout([IS_OPEN_NOTI_DRAWER, IS_SHOW_SEARCH_DROPDOWN]),
		...mapGettersOfLoading([GET_ROUTE_NAME]),
		hasProfileImage() {
			return !isEmptyData(this.userProfileImage);
		},
		currentUrl() {
			return this.$route.fullPath;
		},
		asideZIndex() {
			if (this[IS_OPEN_NOTI_DRAWER] || this[IS_SHOW_SEARCH_DROPDOWN]) {
				return '';
			}

			if (this.isShowProfileImagePopover || this.isShowLoginModal) {
				// popover, modal이 다른 것보다 위로 올라와야 함
				return { zIndex: 100 };
			}

			return '';
		},
	},
	created() {
		this.ROUTE_NAME_FOR_COMMUNITY_INSIGHT = ROUTE_NAME_FOR_COMMUNITY_INSIGHT;
		this.ROUTE_NAME_FOR_COMMUNITY_LOUNGE = ROUTE_NAME_FOR_COMMUNITY_LOUNGE;
	},
	mounted() {
		const sideBoxRef = this.$refs.caseListSideBox;
		const rect = sideBoxRef.getBoundingClientRect();
		const initialTop = rect.top;

		this.scrollHandler = () => {
			const HEADER_HEIGHT = 69;
			const MARGIN_TOP = 32;
			const offset = initialTop - (HEADER_HEIGHT + MARGIN_TOP);
			const subHeader = document.getElementById('subHeader');
			if (subHeader.classList.contains('appear')) {
				sideBoxRef.style.transform = '';
			} else {
				sideBoxRef.style.transform = `translateY(-${offset}px)`;
			}
		};

		window.addEventListener('scroll', this.scrollHandler);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.scrollHandler);
	},
	methods: {
		isEmptyData,
		clickWritingButton() {
			this.clickServiceButton('visitRequest-qa');

			if (!this.isUser) {
				return this.showLoginModal();
			}

			switch (this[GET_ROUTE_NAME]) {
				case this.ROUTE_NAME_FOR_COMMUNITY_INSIGHT:
					return this.goInsightCreate();
				case this.ROUTE_NAME_FOR_COMMUNITY_LOUNGE:
					return this.goLoungeCreate();
				default:
					return this.$_goRequestPageWithCategory();
			}
		},
		showLoginModal() {
			this.isShowLoginModal = true;
		},
		goInsightCreate() {
			// GTM - clickWriteInsightPost
			this.$_clickWriteInsightPost({ ...this.$_userInfoForGtm, category: INSIGHT });
			this.$_routeMixin_go_page(`${INSIGHT_PATH}/create`);
		},
		goLoungeCreate() {
			this.$_clickWriteBBSPost({ ...this.$_userInfoForGtm, category: LOUNGE });
			this.$_routeMixin_go_page(`${LOUNGE_PATH}/create`);
		},
		clickServiceButton(where) {
			// GTM - clickServiceButton
			this.$_clickServiceButton({
				...this.$_userInfoForGtm,
				where: this.$route.query.feed ? 'onboardCaseList' : 'timeline',
				click_to: where,
			});
		},
		getServiceButtonImageSrc(iconName) {
			return `${cdnImageBaseUrl}/community/${iconName}-icon-case-list.svg`;
		},
	},
	components: {
		CaseListServiceButton,
		CustomNuxtLink,
		ProfileImagePopover,
		UserProfileSection,
		MembershipStatusSection,
		CommonLoginModal,
	},
};
