// TODO : 커뮤니티 배너 어드민과 결합해서 관리하도록 수정
import { mapGetters } from 'vuex';

import {
	GET_SHOW_USER_VOICE_ALERT,
	SET_SHOW_USER_VOICE_ALERT,
	mapGettersOfUserVoice,
	mapMutationsOfUserVoice,
} from '@/store/userVoice';

import cookieMixin from '@/mixins/cookieMixin';
import layoutMixin from '@/mixins/layoutMixin';

const userVoiceAlertMixin = {
	mixins: [cookieMixin, layoutMixin],
	created() {
		this.HIDE_USER_VOICE_ALERT = 'HIDE_USER_VOICE_ALERT-1';
	},
	computed: {
		...mapGetters(['isUser']),
		...mapGettersOfUserVoice([GET_SHOW_USER_VOICE_ALERT]),
		$_isTimeline() {
			return this.$route.query.feed === undefined;
		},
		$_showUserVoiceAlert() {
			return this.GET_SHOW_USER_VOICE_ALERT && this.isUser;
		},
		$_showUserVoiceAlertStyle() {
			if (this.$_showUserVoiceAlert && this.IS_MOBILE) {
				return { marginTop: '36px' };
			}
			return null;
		},
	},
	methods: {
		...mapMutationsOfUserVoice([SET_SHOW_USER_VOICE_ALERT]),
		$_checkShowUserVoiceAlertInitially() {
			if (!this.$_getCookie(this.HIDE_USER_VOICE_ALERT)) {
				this[SET_SHOW_USER_VOICE_ALERT](true);
			}
		},
		$_hideUserVoiceAlert() {
			this[SET_SHOW_USER_VOICE_ALERT](false);
			const maxAge = 60 * 60 * 24 * 400;
			this.$_setCookie(this.HIDE_USER_VOICE_ALERT, true, maxAge);
		},
	},
};

export default userVoiceAlertMixin;
