
import { mapGetters } from 'vuex';

import { baseUrl } from '@/plugins/constants';

import { GET_ANSWER_MENTOR_GRADE_INFO, mapGettersOfAnswer } from '@/store/answer';
import { mapGettersOfMyPage, GET_PASS_INFO, GET_IS_LOADED_PASS_INFO } from '@/store/myPage';
import {
	mapGettersOfUserMeta,
	GET_USER_META_NICKNAME,
	GET_USER_META_UNIV,
	GET_USER_META_MAJOR,
} from '@/store/userMeta';

import ImageUploadWrapper from '@/components/common/atoms/ImageUploadWrapper.vue';
import ProfileAvatar from '@/components/common/atoms/ProfileAvatar.vue';

import { profileImageMixin } from '@/mixins/profileImageMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'UserProfileSection',
	mixins: [routeMixin, userInfoMixin, profileImageMixin],
	data() {
		return {
			baseUrl,
		};
	},

	computed: {
		...mapGetters(['isUser', 'userProfileImage']),
		...mapGettersOfMyPage([GET_PASS_INFO, GET_IS_LOADED_PASS_INFO]),
		...mapGettersOfUserMeta([GET_USER_META_NICKNAME, GET_USER_META_UNIV, GET_USER_META_MAJOR]),
		...mapGettersOfAnswer([GET_ANSWER_MENTOR_GRADE_INFO]),
		passUserInfo() {
			return this[GET_PASS_INFO]?.userInfo || {};
		},
		nickname() {
			const passUserInfoValue = this.passUserInfo?.nickname ? this.passUserInfo.nickname : this.defaultNickname;
			if (this[GET_USER_META_NICKNAME]) {
				return this[GET_USER_META_NICKNAME];
			}
			return passUserInfoValue;
		},
		defaultNickname() {
			return `${this.userType === 1 ? '멘티' : '멘토'}${this.userNo}`;
		},
		isMentor() {
			return this.passUserInfo && this.passUserInfo.userType === 2;
		},
		univOrCompany() {
			if (!this.passUserInfo) {
				return '';
			}
			const passUserInfoValue = this.isMentor ? this.passUserInfo.belong : this.passUserInfo.univ;
			if (this[GET_USER_META_UNIV] && !this.isMentor) {
				return this[GET_USER_META_UNIV];
			}
			return passUserInfoValue;
		},
		majorOrDepartment() {
			const passUserInfoValue = this.isMentor ? this.passUserInfo.depart : this.passUserInfo.major;
			if (this[GET_USER_META_MAJOR] && !this.isMentor) {
				return this[GET_USER_META_MAJOR];
			}
			return passUserInfoValue;
		},
		isLoaded() {
			return !this.isUser || this[GET_IS_LOADED_PASS_INFO];
		},
		isAnswer() {
			return this.$route.path.includes('answer');
		},
		gradeInfo() {
			return this[GET_ANSWER_MENTOR_GRADE_INFO] ?? {};
		},
	},
	methods: {
		goLoginPage() {
			this.$_routeMixin_href(`${this.baseUrl}/logout`);
		},
	},
	components: { ImageUploadWrapper, ProfileAvatar, Skeleton },
};
