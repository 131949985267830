import gtmMixin from '@/mixins/gtmMixin';

const communityGtmTriggerMixin = {
	mixins: [gtmMixin],
	methods: {
		$_onboardInsight({ user_no, user_type, email, category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				event: 'onboardInsight',
			});
		},
		$_clickWriteInsightPost({ user_no, user_type, email, category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				event: 'clickWriteInsightPost',
			});
		},
		$_readInsightPost({ user_no, user_type, email, category, post_title, post_no }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				post_title,
				post_no,
				event: 'readInsightPost',
			});
		},
		$_replyInsightPost({ user_no, user_type, email, category, post_title, post_no }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				post_title,
				post_no,
				event: 'replyInsightPost',
			});
		},
		$_writeInsightPost({ user_no, user_type, email, category, post_title, post_no }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				post_title,
				post_no,
				event: 'writeInsightPost',
			});
		},
		$_answerSelected({ link, user_no, email, title, nickname_questioner, nickname_answerer }) {
			this.$_gtm({
				link,
				user_no,
				email,
				title,
				nickname_questioner,
				nickname_answerer,
				event: 'answerSelected',
			});
		},
		$_selectAnswer({ user_no, request_no, request_type, category, credit_amount }) {
			this.$_gtm({
				user_no,
				request_no,
				request_type,
				answer_no: '',
				correspondence_company: '',
				correspondence_industry: '',
				correspondence_job: '',
				correspondence_university: '',
				category,
				credit_amount,
				event: 'selectAnswer',
			});
		},
		/**
		 * 이용권 구매 또는 제휴등록 완료 후 week1(7~13일차)에 재방문하여 readCase를 한 경우 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_week1Retention({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'week1Retention',
			});
		},
		/**
		 * 이용권 구매 또는 제휴등록 완료 후 Month1(30일~59일)에 재방문하여 readCase를 한 경우 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_month1Retention({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'month1Retention',
			});
		},
		/**
		 * 이용권 구매 또는 제휴등록 완료 후 Month3(90일~119일)에 재방문하여 readCase를 한 경우 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_month3Retention({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'month3Retention',
			});
		},
		/**
		 * 이용권 구매 또는 제휴등록 완료 후 Month6(180일~209일)에 재방문하여 readCase를 한 경우 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_month6Retention({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'month6Retention',
			});
		},
		/**
		 * 이용권 구매  또는 제휴 등록 당일에 readCase를 11개 이상 했을 때 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_readCase11Times({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'readCase11Times',
			});
		},
		/**
		 * 이용권 구매  또는 제휴 등록 당일에 readCase를 16개 이상 했을 때 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_readCase16Times({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'readCase16Times',
			});
		},
		/**
		 * readCase 내에서 북마크 추가 버튼을 클릭하여 콘텐츠가 “저장”되었을 때에 발동
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param request_no
		 * @param category
		 * @param where
		 */
		$_addBookMarksCommunity({ user_no, user_type, email, request_no, category, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				request_no,
				category,
				where,
				event: 'addBookMarks_community',
			});
		},
		/**
		 * 헤더의 북마크 버튼을 클릭하여 북마크 리스트 페이지에 진입했을 때에 발동
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_onboardBookMarkPage_community({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'onboardBookMarkPage_community',
			});
		},
		/**
		 * 북마크 리스트에서 북마크해둔 콘텐츠를 클릭했을 때에 발동
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param request_no
		 * @param category
		 * @param where
		 */
		$_clickBookMarkedCard_community({ user_no, user_type, email, request_no, category, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				request_no,
				category,
				where,
				event: 'clickBookMarkedCard_community',
			});
		},
		/**
		 * 광고가 노출되어 impression이 발생된 경우
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param order
		 * @param category
		 * @param where: timeline, readCase
		 * @param job_posting_id: 광고주 번호
		 * @param product_name: 광고 상품명
		 * @param page_depth: 페이지 깊이
		 * @param inventory_number: 광고 재고 번호
		 * @param agency_name: 대행사명
		 * @param client_name: 광고주명
		 * @param user_career: 유저 경력
		 * @param user_university: 유저 학교
		 * @param is_app
		 */
		$_impression({
			user_no,
			user_type,
			email,
			where,
			category,
			order,
			job_posting_id,
			product_name,
			page_depth,
			inventory_number,
			agency_name,
			client_name,
			user_career,
			user_university,
			user_major,
			is_app,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				order,
				where,
				job_posting_id,
				product_name,
				page_depth,
				inventory_number,
				agency_name,
				client_name,
				user_career,
				user_university,
				user_major,
				is_app,
				event: 'impression',
			});
		},
		/**
		 * 광고가 노출되어 viewableImpression이 발생된 경우
		 * impression 발동 조건을 바꾸면서 기존 로직과 비교하기 위해 프로퍼티가 동일한 새로운 이벤트를 만들었음.
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param order
		 * @param category
		 * @param where: timeline, readCase
		 * @param job_posting_id: 광고주 번호
		 * @param product_name: 광고 상품명
		 * @param page_depth: 페이지 깊이
		 * @param inventory_number: 광고 재고 번호
		 * @param agency_name: 대행사명
		 * @param client_name: 광고주명
		 * @param user_career: 유저 경력
		 * @param user_university: 유저 학교
		 * @param is_app
		 */
		$_viewableImpression({
			user_no,
			user_type,
			email,
			where,
			category,
			order,
			job_posting_id,
			product_name,
			page_depth,
			inventory_number,
			agency_name,
			client_name,
			user_career,
			user_university,
			user_major,
			is_app,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				order,
				where,
				job_posting_id,
				product_name,
				page_depth,
				inventory_number,
				agency_name,
				client_name,
				user_career,
				user_university,
				user_major,
				is_app,
				event: 'viewableImpression',
			});
		},
		/**
		 * 노출된 광고를 클릭한 경우
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param where
		 * @param order
		 * @param category
		 * @param job_posting_id
		 * @param product_name: 광고 상품명
		 * @param page_depth: 페이지 깊이
		 * @param inventory_number: 광고 재고 번호
		 * @param agency_name: 대행사명
		 * @param client_name: 광고주명
		 * @param user_career: 유저 경력
		 * @param user_university: 유저 학교
		 * @param is_app
		 */
		$_clickAdBanner({
			user_no,
			user_type,
			email,
			where,
			order,
			category,
			job_posting_id,
			product_name,
			page_depth,
			inventory_number,
			agency_name,
			client_name,
			user_career,
			user_university,
			user_major,
			is_app,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				order,
				category,
				job_posting_id,
				product_name,
				page_depth,
				inventory_number,
				agency_name,
				client_name,
				user_career,
				user_university,
				user_major,
				is_app,
				event: 'click_ad_banner',
			});
		},
		/**
		 * 진급 미션 출력시 발동
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_showMentorMission({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'showMentorMission',
			});
		},
		/**
		 * 진급 미션 완료시 발동
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_completeMentorMission({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'completeMentorMission',
			});
		},
		/**
		 * 멘티.ver 미션 출력시 발동
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_showMenteeMission({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'showMenteeMission',
			});
		},
		/**
		 * 멘티.ver 미션 완료시 발동
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_completeMenteeMission({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'completeMenteeMission',
			});
		},
		/**
		 * gtm showCSAT
		 * @param {Object} params
		 * @param {string} params.user_no - The name of the employee.
		 * @param {string} params.user_type - The employee's department.
		 * @param {string} params.email - The employee's department.
		 * @param {string} params.membership_type
		 * @type {'b2c'|'b2b'}
		 * @param {string} params.where - 'readCase'
		 **/
		$_showCSAT({ user_no, user_type, email, membership_type, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				membership_type,
				where,
				event: 'showCSAT',
			});
		},
		/**
		 * gtm submitCSAT
		 * @param {Object} params
		 * @param {string} params.user_no - The name of the employee.
		 * @param {string} params.user_type - The employee's department.
		 * @param {string} params.email - The employee's department.
		 * @param {string} params.membership_type
		 * @type {'b2c'|'b2b'}
		 * @param {string} params.where - 'readCase'
		 * @param {number} params.rate - 1~5
		 * @param {number} params.request_no - 질문 번호
		 * @param {number} params.search_keyword - 검색 키워드
		 * **/
		$_submitCSAT({ user_no, user_type, email, membership_type, where, rate, request_no, search_keyword }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				membership_type,
				where,
				rate,
				request_no,
				search_keyword,
				event: 'submitCSAT',
			});
		},
		/**
		 * gtm clickCaseCard
		 * @param {Object} params
		 * @param {string} params.user_no - The name of the employee.
		 * @param {string} params.user_type - The employee's department.
		 * @param {string} params.email - The employee's department.
		 * @param {string} params.category - The employee's department.
		 * @param {string} params.authority - 현재 유효한 이용권한, 조회권한 Y, N
		 * @param {string} params.job_filter - 직무 필터
		 *
		 **/
		$_clickCaseCard({ user_no, user_type, email, category, authority, where, job_filter }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				category,
				authority,
				where,
				job_filter,
				event: 'clickCaseCard',
			});
		},
		/**
     * gtm click_lms_com
     * @param {Object} params
     * @param {string} params.user_no - The name of the employee.
     * @param {string} params.user_type - The employee's department.
     * @param {string} params.email - The employee's department.
     * @param {number} params.group_id - The employee's department.
     * @param {string} params.link - The employee's department.
     * @param {string} params.camp_category - The employee's department.

     **/
		$_clickLmsCom({ user_no, user_type, email, group_id, link, camp_category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				group_id,
				link,
				camp_category,
				event: 'click_lms_com',
			});
		},
		/**
		 * gtm showMentorFeedbackList
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param where
		 * @param authority
		 * @param correspondence_company
		 * @param correspondence_industry
		 * @param correspondence_job
		 * @param correspondence_university
		 */
		$_showMentorFeedbackList({
			user_no,
			user_type,
			email,
			where,
			authority,
			correspondence_company,
			correspondence_industry,
			correspondence_job,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				authority,
				correspondence_company,
				correspondence_industry,
				correspondence_job,
				event: 'showMentorFeedbackList',
			});
		},
		/**
		 * gtm clickServiceButton
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param where
		 * @param click_to
		 */
		$_clickServiceButton({ user_no, user_type, email, where, click_to }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				click_to,
				event: 'clickServiceButton',
			});
		},
		/**
		 * gtm showServiceGuideBanner
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_showServiceGuideBanner({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'showServiceGuideBanner',
			});
		},
		/**
		 * gtm clickServiceGuideBanner
		 * @param user_no
		 * @param user_type
		 * @param email
		 */
		$_clickServiceGuideBanner({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'clickServiceGuideBanner',
			});
		},
		/**
		 * gtm selectSearchOption
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param search_keyword
		 * @param where
		 * @param category
		 */
		$_selectSearchOption({ user_no, user_type, email, search_keyword, where, category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				search_keyword,
				where,
				category,
				event: 'selectSearchOption',
			});
		},
		/**
		 * gtm clickJobFilter
		 * 취업고민 등의 onboardCaseList에서 직무 필터 클릭하여 활성화된 경우 발생
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param authority
		 * @param category
		 * @param where
		 */
		$_clickJobFilter({ user_no, user_type, email, membership_type, category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				membership_type,
				category,
				event: 'clickJobFilter',
			});
		},
		/**
		 * gtm submitReMentoringRequest
		 * 리멘토링 수요 조사 제출 시 발동
		 * @param user_no
		 * @param user_type
		 * @param email
		 * @param answer_no
		 * @param answer_date
		 * @param reason
		 */
		$_submitReMentoringRequest({ user_no, user_type, email, answer_no, answer_date, reason }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				answer_no,
				answer_date,
				reason,
				event: 'submitReMentoringRequest',
			});
		},
		$_selectJobFilter({ user_no, user_type, email, membership_type, job_filter }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				membership_type,
				job_filter,
				event: 'selectJobFilter',
			});
		},
		$_clickSearchCategoryChip({ user_no, user_type, email, search_keyword, category }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				search_keyword,
				category,
				event: 'clickSearchCategoryChip',
			});
		},
		$_showAppBanner({ user_no, user_type, email, where, authority }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				authority,
				event: 'showAppBanner',
			});
		},
		$_clickAppBanner({ user_no, user_type, email, where, authority }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				authority,
				event: 'clickAppBanner',
			});
		},
		$_selectAnswerKeyword({
			user_no,
			user_type,
			email,
			request_no,
			request_type,
			answer_no,
			keyword_count,
			select_keyword,
		}) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				request_no,
				request_type,
				answer_no,
				keyword_count,
				select_keyword,
				event: 'selectAnswerKeyword',
			});
		},
	},
};

export default communityGtmTriggerMixin;
