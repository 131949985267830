
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'MypageSettingProfileGradeCheckList',
	data() {
		return {
			promotionCheckList: Object.freeze([
				{
					id: 1,
					content: '누적 크레딧이 진급 기준 이상인가요?',
				},
				{
					id: 2,
					content: '채택률이 진급 기준 이상인가요?',
				},
				{
					id: 3,
					content: '진급!',
				},
			]),
		};
	},
});
