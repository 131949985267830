
import { cdnBaseUrl } from '@/plugins/constants';

export default {
	name: 'CreditShopItem',
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	created() {
		this.cdnBaseUrl = cdnBaseUrl;
	},
	methods: {
		goShopDetail({ id, type }) {
			this.$emit('go-shop-detail', { id, type });
		},
	},
};
