import gtmMixin from '@/mixins/gtmMixin';

const myPageGtmTriggerMixin = {
	mixins: [gtmMixin],
	methods: {
		$_onboardMyPage({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'onboardMyPage',
			});
		},
		$_onboardMyPageMatchingMenu({ user_no, user_type, email, optIn }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				optIn,
				event: 'onboardMyPageMatchingMenu',
			});
		},
		$_optIn({ user_no, user_type, email, where, type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				type,
				event: 'optIn',
			});
		},
		$_optOut({ user_no, user_type, email, where, type }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				type,
				event: 'optOut',
			});
		},
		$_registerProfilePic({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'registerProfilePic',
			});
		},
		$_showCTAButtonOnMyPage({ user_no, user_type, email, where }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				event: 'showCTAButtonOnMyPage',
			});
		},
		$_clickCTAButtonOnMyPage({ user_no, user_type, email, where, click_to }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				where,
				click_to,
				event: 'clickCTAButtonOnMyPage',
			});
		},
		$_requestMentorAuth({ user_no, user_type, email, auth_type, pincode, company, job }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				auth_type,
				pincode,
				company,
				job,
				event: 'requestMentorAuth',
			});
		},
		$_mentorAuthPopup({ user_no, user_type, email }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				event: 'mentorAuthPopup',
			});
		},
		$_mentorRegisterButtonClick({ user_no, user_type, email, company, job }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				company,
				job,
				event: 'mentorRegisterButtonClick',
			});
		},
		$_mentorRegisterSucceeded({ user_no, user_type, email, company, job, date }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				company,
				job,
				date,
				mentor_company_name: company,
				mentor_job_position: job,
				event: 'mentorRegisterSucceeded',
			});
		},
		// 멘토 최초 인증 시(멘티 -> 멘토)
		$_mentorRegisterFirstTime({ user_no, user_type, email, company, job, date }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				company,
				job,
				date,
				event: 'mentorRegisterFirstTime',
			});
		},
		$_changeNotiSetting({ user_no, email, user_type, notice_optIn, optIn, mobile }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				notice_optIn,
				optIn,
				mobile,
				event: 'changeNotiSetting',
			});
		},
		$_exchangeGift({ user_no, user_type, email, user_level, brand_name, gift_name, revenue_amount }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				user_level,
				brand_name,
				gift_name,
				revenue_amount,
				event: 'exchangeGift',
			});
		},
		$_exchangeMoney({ user_no, user_type, email, user_level, revenue_amount }) {
			this.$_gtm({
				user_no,
				user_type,
				email,
				user_level,
				revenue_amount,
				event: 'exchangeMoney',
			});
		},
	},
};

export default myPageGtmTriggerMixin;
